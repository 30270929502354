import React, { useEffect } from "react";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Container from '@mui/material/Container';
import { errorMsg, validateFields } from "./utils/formUtils";
import { connect } from "react-redux";
import { setKepler } from "../redux/actions";
import { Button, CircularProgress } from "@mui/material";
import { updateOrbitOfSat } from "./utils/scenarioQery";


function KeplerianForm(props) {
  const initFields = {
    semiMajorAxis: 7500,
    eccentricity: 0.003,
    inclination: 80,
    raan: 90,
    argOfPeriapsis: 90,
    trueAnomaly: 0,
    invalidFields: {
      startTime: false,
      endTime: false,
      timeResolution: false,
      semiMajorAxis: false,
      eccentricity: false,
      inclination: false,
      raan: false,
      argOfPeriapsis: false,
      trueAnomaly: false,
    },
  };
  const [fields, setFields] = React.useState(initFields);
  const [isSaving, setSaving] = React.useState(false);

  useEffect(() => {
    if (props.orbit) {
      setFields(old => ({
        ...old, 
        semiMajorAxis: parseFloat(props.orbit.semiMajorAxis),
        eccentricity: parseFloat(props.orbit.eccentricity),
        inclination: parseFloat(props.orbit.inclination),
        raan: parseFloat(props.orbit.raan),
        argOfPeriapsis: parseFloat(props.orbit.argOfPeriapsis),
        trueAnomaly: parseFloat(props.orbit.trueAnomaly),
      }))
    }
    console.log('tikk');
  }, [props.orbit])

  const handleChange = (value) => {
    let newFields = {...fields, ...value};
    setFields(validateFields(newFields));

    if (props.scenarioKey === 'new-scenario') {
      props.setKepler(props.scenarioKey, {
        semiMajorAxis: parseFloat(newFields.semiMajorAxis),
        inclination: parseFloat(newFields.inclination),
        raan: parseFloat(newFields.raan),
        eccentricity: parseFloat(newFields.eccentricity),
        argOfPeriapsis: parseFloat(newFields.argOfPeriapsis),
        trueAnomaly: parseFloat(newFields.trueAnomaly),
      });
    }

  };

  const handleSave = () => {
    setSaving(true)
    console.log(fields);
    updateOrbitOfSat(props.scenarioKey, {
      semiMajorAxis: parseFloat(fields.semiMajorAxis),
      eccentricity: parseFloat(fields.eccentricity),
      inclination: parseFloat(fields.inclination),
      raan: parseFloat(fields.raan),
      argOfPeriapsis: parseFloat(fields.argOfPeriapsis),
      trueAnomaly: parseFloat(fields.trueAnomaly),
      type: 'KEPLER',
    })
    .then(setSaving(false))
  }

  return (
    <Container maxWidth={false}>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '22ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          error={fields.invalidFields.semiMajorAxis}
          id="semiMajorAxis"
          label="Semi-major axis"
          helperText={fields.invalidFields.semiMajorAxis ? errorMsg.semiMajorAxis : ' '}
          InputProps={{
            endAdornment: <InputAdornment position="start">Km</InputAdornment>,
          }}
          type="number"
          variant="outlined"
          value={fields.semiMajorAxis}
          onChange={(e) => handleChange({semiMajorAxis: e.target.value})}
          size="small"
          disabled={props.isDemo}
        />

        <TextField
          error={fields.invalidFields.inclination}
          id="inclination"
          label="Inclination"
          helperText={fields.invalidFields.inclination ? errorMsg.inclination : ' '}
          InputProps={{
            endAdornment: <InputAdornment position="start">deg</InputAdornment>,
          }}
          type="number"
          variant="outlined"
          value={fields.inclination}
          onChange={(e) => handleChange({inclination: e.target.value})}
          size="small"
          disabled={props.isDemo}
        />

        <TextField
          error={fields.invalidFields.raan}
          id="raan"
          label="RAAN"
          helperText={fields.invalidFields.raan ? errorMsg.raan : ' '}
          InputProps={{
            endAdornment: <InputAdornment position="start">deg</InputAdornment>,
          }}
          type="number"
          variant="outlined"
          value={fields.raan}
          onChange={(e) => handleChange({raan: e.target.value})}
          size="small"
          disabled={props.isDemo}
        />

        <TextField
          error={fields.invalidFields.eccentricity}
          id="eccentricity"
          label="Eccentricity"
          helperText={fields.invalidFields.eccentricity ? errorMsg.eccentricity : ' '}
          type="number"
          variant="outlined"
          value={fields.eccentricity}
          onChange={(e) => handleChange({eccentricity: e.target.value})}
          size="small"
          disabled={props.isDemo}
        />

        <TextField
          error={fields.invalidFields.argOfPeriapsis}
          id="argOfPeriapsis"
          label="Argument of periapsis"
          helperText={fields.invalidFields.argOfPeriapsis ? errorMsg.argOfPeriapsis : ' '}
          InputProps={{
            endAdornment: <InputAdornment position="start">deg</InputAdornment>,
          }}
          type="number"
          variant="outlined"
          value={fields.argOfPeriapsis}
          onChange={(e) => handleChange({argOfPeriapsis: e.target.value})}
          size="small"
          disabled={props.isDemo}
        />

        <TextField
          error={fields.invalidFields.trueAnomaly}
          id="trueAnomaly"
          label="True anomaly"
          helperText={fields.invalidFields.trueAnomaly ? errorMsg.trueAnomaly : ' '}
          InputProps={{
            endAdornment: <InputAdornment position="start">deg</InputAdornment>,
          }}
          type="number"
          variant="outlined"
          value={fields.trueAnomaly}
          onChange={(e) => handleChange({trueAnomaly: e.target.value})}
          size="small"
          disabled={props.isDemo}
        />
      </Box>
      {(props.scenarioKey === 'new-scenario') ||
        <Button 
          variant="contained"
          onClick={handleSave}
          disabled={isSaving || props.isDemo}
        >
          {isSaving ? <CircularProgress /> : 'Save'}
        </Button>
      }
    </Container>
  );
}

const mapStateToProps = state => {
  return { scenarioKey: state.currentScenarioKey, };
};

export default connect( mapStateToProps, { setKepler })(KeplerianForm);