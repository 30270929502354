import React, { useEffect, useState } from "react";
import { Button, Container } from "@mui/material";
import { connect } from "react-redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CardMedia from "@mui/material/CardMedia";
import CalculateButton from "./ui-components/CalculateButton";
import { 
    setGroundTrack, 
    setEarth, 
    setSun, 
    setOrbitType, 
    setDoneAnalytics,
    setSatAttitude, 
    setIncomingPower 
} from "../redux/actions";
import { runPropagator, runRadiation, runLifetime, runPower, runRfData } from "./utils/calculations";
import { tleParser } from "./utils/tleParser";
import TleTable from "./ui-components/TleTable";
import { getScenarioById } from "./utils/scenarioQery";
import { useNavigate } from "react-router-dom";


function ScenarioDashboard(props) {
    const navigate = useNavigate();
    console.log(props);
    const [scen, setScen] = useState({});
    const [sat, setSat] = useState({});
    const [orbit, setOrbit] = useState({});
    const [attitude, setAttitude] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [jsonFiles, setJsonFiles] = useState({});
    const setSatAttitude = props.setSatAttitude

    useEffect(() => {
        getScenarioById(props.scenarioKey)
        .then(({scenario, satellite, orbit, attitude}) => {
            setScen(scenario);
            setSat(satellite);
            setOrbit(orbit);
            setAttitude(attitude);
            setSatAttitude(props.scenarioKey, {
                isRotating: attitude.rotation,
                rotation: {
                  bodyX: attitude.rotationAxisX,
                  bodyY: attitude.rotationAxisY,
                  bodyZ: attitude.rotationAxisZ,
                  rotationSpeed: attitude.rotationSpeed,
                },
                alignment: {
                    satVector: attitude.alignmentSatVector,
                    alignmentVector: attitude.alignmentVector,
                },
                constraint: {
                    satVector: attitude.constraintSatVector,
                    constraintVector: attitude.constraintVector,
                },
            })

            fetch(satellite.areaFile)
            .then((response) => response.json())
            .then((json) => {setJsonFiles(json)})
            .catch((error) => console.error(error))
            
        })
        .finally(() => setLoading(false));
        console.log('qery');
    }, [props.scenarioKey, setSatAttitude])

    console.log(jsonFiles);
    return (isLoading ||
        <Container maxWidth='xl' sx={{paddingTop: 10, paddingBottom: 10}}>
            <h1>Dashboard: {scen.name}</h1>
            <Box sx={{ width: '100%' }}>
                <Stack spacing={2}>
                    <Paper>
                        <h4>Details</h4>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="Details">
                            <TableBody>
                            {[
                                {name: "Name", value: scen.name},
                                {name: "Description", value: scen.description},
                                {name: "Start Time", value: scen.startTime},
                                {name: "End Time", value: scen.endTime},
                                {name: "Time Resolution", value: scen.timeResolution},
                            ].map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                    <TableCell align="left">{row.value}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </Paper>
                    <Paper>
                        <h4>Satellite</h4>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="Satellite table">
                            <TableBody>
                            {[
                                {name: "Name", value: sat.name},
                                {name: "Size", value: sat.size},
                                {name: "Satellite Key", value: sat.satKey},
                                {name: "Wing Configurations", value: sat.wings},
                                {name: "Area of the Solar Panels", value: sat.solarArea},
                                {name: "Number of Solar Panels", value: sat.solarCellNr},
                            ].map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                    <TableCell align="left">{row.value}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <CardMedia
                            component={sat.mediaType}
                            src={sat.mediaUrl}
                            title={sat.name}
                            onMouseOver={e => (sat.mediaType==='video') && e.target.play()}
                            onMouseOut={e => (sat.mediaType==='video') && e.target.pause()}
                            loop
                            muted
                            sx={{maxWidth: "100%"}}
                        />
                    </Paper>
                    <Paper>
                        <h4>Orbit</h4>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="Orbit table">
                            <TableBody>
                            {(orbit.type === 'TLE' ?
                            [
                                {name: "Line 1", value: orbit.tleLine1},
                                {name: "Line 2", value: orbit.tleLine2},
                            ] : [
                                {name: "Semi Major Axis", value: orbit.semiMajorAxis},
                                {name: "Inclination", value: orbit.inclination},
                                {name: "Eccentricity", value: orbit.eccentricity},
                                {name: "Arg. of Periapsis", value: orbit.argOfPeriapsis},
                                {name: "RAAN", value: orbit.raan},
                                {name: "True Anomaly", value: orbit.trueAnomaly},
                            ]).map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                    <TableCell align="left" sx={{fontFamily: ["Roboto Mono", "monospace"]}}>{row.value}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <br/>
                        {(orbit.type === 'TLE') &&
                            <TleTable tleTables={tleParser([orbit.tleLine1, orbit.tleLine2].join('\n'))} />
                        }

                        <h4>Nominal Attitude</h4>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="Orbit table">
                            <TableBody>
                            {(attitude.rotation 
                            ? [
                                {name: "rotationAxisX", value: attitude.rotationAxisX},
                                {name: "rotationAxisY", value: attitude.rotationAxisY},
                                {name: "rotationAxisZ", value: attitude.rotationAxisZ},
                                {name: "rotationSpeed", value: attitude.rotationSpeed},
                            ] : [
                                {name: "alignmentSatVector", value: attitude.alignmentSatVector},
                                {name: "alignmentVector", value: attitude.alignmentVector},
                                {name: "constraintSatVector", value: attitude.constraintSatVector},
                                {name: "constraintVector", value: attitude.constraintVector},
                            ]).map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                    <TableCell align="left">{row.value}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        <CalculateButton 
                            fn={runPropagator} 
                            args={{
                                setGroundTrack: props.setGroundTrack,
                                setSun: props.setSun,
                                setEarth: props.setEarth,
                                setDoneAnalytics: props.setDoneAnalytics,
                                scenarioKey: props.scenarioKey,
                                times: {
                                    startTime: scen.startTime, 
                                    endTime: scen.endTime, 
                                    timeResolution: scen.timeResolution, 
                                },
                                orbit:{
                                    type: orbit.type,
                                    tle: {
                                        line1: orbit.tleLine1, 
                                        line2: orbit.tleLine2,
                                    },
                                    kepler: {
                                        semiMajorAxis: orbit.semiMajorAxis,
                                        inclination: orbit.inclination,
                                        eccentricity: orbit.eccentricity,
                                        argOfPeriapsis: orbit.argOfPeriapsis,
                                        trueAnomaly: orbit.trueAnomaly,
                                        raan: orbit.raan,
                                    },
                                }
                            }} 
                            success={props.done ? props.done.satOrbit : false}/>
                            {props.done.satOrbit && 
                            <Button 
                                disabled={!props.done.satOrbit}
                                onClick={() => navigate('/scenario/orbit')}
                            >
                                Go to analytics
                            </Button>
                            }
                    </Paper>
                    <Paper>
                        <h4>Radiation</h4>
                        <CalculateButton fn={runRadiation} args={props} success={props.done ? props.done.radiation : false}/>
                        {props.done.radiation && 
                        <Button 
                                disabled={!props.done.radiation}
                                onClick={() => navigate('/scenario/radiation')}
                            >
                                Go to analytics
                        </Button>
                        }
                    </Paper>
                    <Paper>
                        <h4>Lifetime</h4>
                        <CalculateButton fn={runLifetime} args={props} success={props.done ? props.done.lifetime : false}/>
                        {props.done.lifetime && 
                        <Button 
                                disabled={!props.done.lifetime}
                                onClick={() => navigate('/scenario/lifetime')}
                            >
                                Go to analytics
                        </Button>
                        }
                    </Paper>
                    <Paper>
                        <h4>Power</h4>
                        {(props.done.satOrbit && props.predictions) ?
                            <CalculateButton 
                                fn={runPower} 
                                args={{
                                    scenarioKey: props.scenarioKey,
                                    setDoneAnalytics: props.setDoneAnalytics,
                                    setIncomingPower: props.setIncomingPower,
                                    satEciTs: props.predictions.satPos.positionsEci,
                                    sunEciTs: props.predictions.solarSystem.sun.positionEci,
                                    attitude: {
                                        alignment: {
                                            satVector: attitude.alignmentSatVector,
                                            alignmentVector: attitude.alignmentVector,
                                        },
                                        constraint: {
                                            satVector: attitude.constraintSatVector,
                                            constraintVector: attitude.constraintVector,
                                        },
                                    },
                                    // TOD BUG FIX: satKeys in the power json files
                                    satPowerMap: Object.values(jsonFiles)[0]
                                }} 
                                success={props.done ? props.done.incomingPower : false}
                            />:<>Run Orbit Propagation First!</>
                        }
                        {props.done.incomingPower && 
                        <Button 
                            disabled={!props.done.incomingPower}
                            onClick={() => navigate('/scenario/power')}
                        >
                            Go to analytics
                        </Button>
                        }
                    </Paper>
                    <Paper>
                        <h4>RF / Data</h4>
                        <CalculateButton fn={runRfData} args={props} success={props.done ? props.done.rf : false}/>
                        {props.done.rf && 
                        <Button 
                            disabled={!props.done.rf}
                            onClick={() => navigate('/scenario/rfdata')}
                        >
                            Go to analytics
                        </Button>
                        }
                    </Paper>
                </Stack>
            </Box>
        </Container>
    );
}

const mapStateToProps = state => {
    if (state.scenarios[state.currentScenarioKey]) {
        return { 
            scenarioKey: state.currentScenarioKey,
            done: state.doneAnalytics[state.currentScenarioKey],
            predictions: state.scenarios[state.currentScenarioKey].analytics.predictions,
        }
    }
    return { 
        scenarioKey: state.currentScenarioKey,
        done: state.doneAnalytics[state.currentScenarioKey],
    }
}

export default connect(mapStateToProps, { 
    setGroundTrack, 
    setEarth, 
    setSun, 
    setOrbitType, 
    setDoneAnalytics,
    setSatAttitude,
    setIncomingPower,
})(ScenarioDashboard);