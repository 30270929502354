import React, { useState } from "react";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { Auth } from 'aws-amplify';
import Box from '@mui/material/Box';
import { DataStore } from '@aws-amplify/datastore';
import { 
    // User, 
    Organization } from '../models';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Settings() {
    const initAlertState = {open: false, msg: 'Done', severity: 'success'}
    const [alertState, setAletrState] = useState(initAlertState);
    const [values, setValues] = useState({
        username: '',
        phoneNumber: '',
        email: '',
        oldPassword: '',
        password1x: '',
        password2x: '',
        showOldPassword: false,
        showPassword1x: false,
        showPassword2x: false,
    });
    const [org, setOrg] = useState({name: '', website: ''});

    React.useEffect( () => {
        const getAttributes = async () => {
            const { attributes } = await Auth.currentAuthenticatedUser();
            console.log(attributes);
            return attributes;
        };

        // const getUser = async () => {
        //     const models = await DataStore.query(User);
        //     console.log(models);
        // }

        const getOrganization = async () => {
            const models = await DataStore.query(Organization);
            console.log(models);
        }

        // getUser();
        getOrganization();

        getAttributes()
        .then(attr => {
            setValues(v => {
                return{ 
                    ...v, 
                    username: attr.name,
                    email: attr.email, 
                    phoneNumber: attr.phone_number }
                }
            );
        })
        .catch(err => { setAletrState({open: true, msg: err.message, severity: 'error'}) })
    },[]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = (showPassword) => () => {
        setValues({ ...values, [showPassword]: !values[showPassword] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const isPwdOK = () => {
        if (values.password1x !== values.password2x) {
            setAletrState({open: true, msg: 'Passwords are not the same!', severity: 'error'});
            return false;
        } else if (values.password1x === '' || values.password2x === '') {
            setAletrState({open: true, msg: 'Empty Password!', severity: 'error'});
            return false;
        }
        return true;
    }

    const saveNewPassword = () => {
        if (isPwdOK()) {
            Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, values.oldPassword, values.password1x);
            })
            .then(data => {
                setAletrState({open: true, msg: data, severity: 'success'});
                setValues(v => {
                    return{ 
                        ...v, 
                        oldPassword: '',
                        password1x: '',
                        password2x: '', }
                    }
                );
            })
            .catch(err => {
                setAletrState({open: true, msg: err.message, severity: 'error'});
            });
        }
    }

    const saveNewUserAttributes = async () => {
        let user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            'name': values.username,
            'phone_number': values.phoneNumber,
        }).then(res => {
            setAletrState({open: true, msg: res, severity: 'success'});
        })
        .catch(err => {
            setAletrState({open: true, msg: err.message, severity: 'error'});
        });
    }

    const saveOrg = async () => {
        console.log('Not implemented yet!');
    }

    const handleAlertClose = (_event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAletrState(initAlertState);
    };

    return (
        <Box className="Settings" sx={{paddingTop: 10, paddingBottom: 10}}>
            <Snackbar open={alertState.open} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={alertState.severity} sx={{ width: '100%' }}>
                    {alertState.msg}
                </Alert>
            </Snackbar>

            <h3>Organisation</h3>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '28ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <MuiAlert severity="info" sx={{margin: '1rem 5rem'}}>
                    Organization is a paid feature.
                </MuiAlert>
            <div>
                <TextField
                    disabled
                    id="org-name"
                    size="small"
                    label="Organization"
                    value={org.name}
                    variant="outlined"
                    onChange={e => setOrg(o => ({...o, name: e.target.value}))}
                />
                <br/>
                <TextField
                    disabled
                    id="org-websitte"
                    size="small"
                    label="Website"
                    value={org.website}
                    variant="outlined"
                    onChange={e => setOrg(o => ({...o, website: e.target.value}))}
                />
            </div>
                <Button
                    disabled
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={saveOrg}
                >
                    Save
                </Button>
            </Box>

            <h3>User</h3>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '28ch' },
                }}
                noValidate
                autoComplete="off"
            >
            <div>
                <TextField
                    disabled
                    id="email"
                    size="small"
                    label="Email"
                    value={values.email}
                    variant="outlined"
                    // onChange={handleChange('email')}
                    type="email"
                />
                <br/>
                <TextField
                    id="username"
                    size="small"
                    label="Username"
                    value={values.username}
                    variant="outlined"
                    onChange={handleChange('username')}
                />
                <br/>
                <TextField
                    id="phoneNumber"
                    size="small"
                    label="Phone Number"
                    value={values.phoneNumber}
                    variant="outlined"
                    onChange={handleChange('phoneNumber')}
                    type="tel"
                />
            </div>

                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={() => saveNewUserAttributes()}
                >
                    Save
                </Button>
            </Box>


            <h3>Change Password</h3>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '28ch' },
                }}
                noValidate
                autoComplete="off"
            >
            
            <FormControl 
                sx={{ m: 1, width: '25ch' }}
                variant="outlined"
                size="small"
            >
                <InputLabel htmlFor="oldPassword">Old Password</InputLabel>
                <OutlinedInput
                    id="oldPassword"
                    type={values.showOldPassword ? 'text' : 'password'}
                    value={values.oldPassword}
                    onChange={handleChange('oldPassword')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword('showOldPassword')}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {values.showOldPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <br/>
            <FormControl 
                sx={{ m: 1, width: '25ch' }}
                variant="outlined"
                size="small"
            >
                <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                <OutlinedInput
                    id="password"
                    type={values.showPassword1x ? 'text' : 'password'}
                    value={values.password1x}
                    onChange={handleChange('password1x')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword('showPassword1x')}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {values.showPassword1x ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <br/>
            <FormControl 
                sx={{ m: 1, width: '25ch' }}
                variant="outlined"
                size="small"
            >
                <InputLabel htmlFor="outlined-adornment-password">Confirm New Password</InputLabel>
                <OutlinedInput
                    id="confirm-password"
                    type={values.showPassword2x ? 'text' : 'password'}
                    value={values.password2x}
                    onChange={handleChange('password2x')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword('showPassword2x')}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {values.showPassword2x ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            </Box>
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SaveIcon />}
                onClick={() => saveNewPassword()}
            >
                Save
            </Button>
            
        </Box>
    );
}
