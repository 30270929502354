import React, { Suspense } from "react";

const Plot = React.lazy(() => import('react-plotly.js'));

export function TsLineChart({ts, data, solarRadiation}) {
    return(
        <div className="LineChart">
            <Suspense fallback={<div>Loading...</div>}>
            <Plot
                data={[
                {
                    x: ts.map(t => new Date(t)),
                    y: data.map(d => d * solarRadiation),
                    // mode: 'lines',
                    type: "scatter",
                    mode: "lines",
                },
                ]}
                layout={{
                    width: 600, 
                    height: 600, 
                    title: 'Incoming Power vs Time',
                    xaxis: { title: { text: 'Date', },
                            tickangle: -45,
                            tickformat: '%Y-%m-%d\n%H:%M',
                           },
                    yaxis: { title: { text: 'Incoming Power [W]', } },
                }}
            />
            </Suspense>
        </div>
    )
}


export function PowerChart3D({data, solarRadiation}) {
    const arrayCol = (arr, col) => arr.map(x => x[col]);
    const uniqueVals = dataList => [...new Set(dataList)];
    const dataZmap = dd => {
        let kk = [];
        const y = uniqueVals(arrayCol(dd, 1));
        // 1362 W/m2 is the solar radiation at the LEO orbit
        kk = y.map(yi => dd.filter(ddi => yi === ddi[1]).map(ii => ii[2] * solarRadiation));
        return kk;
    }

    // const calcXYZ = dd => dd.map(d => {
    //     const x = 10 * d[2] * Math.cos(d[1]*(Math.PI/180)) * Math.sin((d[0]+90)*(Math.PI/180));
    //     const y = 10 * d[2] * Math.sin(d[1]*(Math.PI/180)) * Math.sin((d[0]+90)*(Math.PI/180));
    //     const z = 10 * d[2] * Math.cos((d[0]+90)*(Math.PI/180));
    //     return([x ,y ,z]);
    // })

    // const x = arrayCol(calcXYZ(data), 0);
    // const y = arrayCol(calcXYZ(data), 1);
    // const z = arrayCol(calcXYZ(data), 2);
    // console.log([x,y,z]);
    return (
        <div className="PowerChart3D">
            <Suspense fallback={<div>Loading...</div>}>
            <Plot
                data={[
                {
                    x: uniqueVals(arrayCol(data, 0)),
                    y: uniqueVals(arrayCol(data, 1)),
                    z: dataZmap(data),
                    // x: x, 
                    // y: y,
                    // z: z,

                    // type: 'surface',
                    type: 'contour',
                    // type: 'scatter3d',
                    // mode: 'lines',
                    // mode: 'lines+markers',
                    // marker: {color: 'red'},
                    // colorscale: 'Viridis',
                    // lighting: {ambient: 0.9}
                    contours: {
                        coloring: 'heatmap',
                        showlabels: true,
                        labelfont: {
                          family: 'Raleway',
                          size: 12,
                          color: 'white',
                        }
                    },
                    colorbar:{
                        title: 'Incoming power [W2]',
                        titleside: 'right',
                        titlefont: {
                          size: 14,
                          family: 'Arial, sans-serif'
                        }
                    }
                },
                ]}
                layout={{
                    width: 600, 
                    height: 600, 
                    title: "Satellite illumination vs Sun vector in the refference frame of the sat",
                    xaxis: { title: { text: 'theta [deg]', } },
                    yaxis: { title: { text: 'phi [deg]', } },
                }}
            />
            </Suspense>

        </div>
    );
}
