import {
    Vector3,
    Color3,
    Mesh,
    DynamicTexture,
    StandardMaterial,
    HighlightLayer,
    MeshBuilder,
    Texture,
    TransformNode
} from '@babylonjs/core';

const axis_arrow = function (axis_name, size, color = new Color3(1, 1, 1), scene) {
    let line_coords = [size, 0, 0];
    let arrow_1 = [size * 0.95, 0.05 * size, 0];
    let arrow_2 = [size * 0.95, -0.05 * size, 0];
    if (axis_name === "Y") {
        line_coords.unshift(line_coords.pop());
        arrow_1.unshift(arrow_1.pop());
        arrow_2.unshift(arrow_2.pop());
    };
    if (axis_name === "Z") {
        line_coords.push(line_coords.shift());
        arrow_1.push(arrow_1.shift());
        arrow_2.push(arrow_2.shift());
    };
    var axis = Mesh.CreateLines(
        "axis" + axis_name,
        [
            new Vector3.Zero(),
            new Vector3(...line_coords),
            new Vector3(...arrow_1),
            new Vector3(...line_coords),
            new Vector3(...arrow_2)
        ],
        scene);
    axis.color = color;
    return axis;
}

export function showAxis(size, scene) {
    var makeTextPlane = function (text, color, size) {
        var dynamicTexture = new DynamicTexture("DynamicTexture", 50, scene, true);
        dynamicTexture.hasAlpha = true;
        dynamicTexture.drawText(text, 5, 40, "bold 36px Arial", color, "transparent", true);
        var plane = new Mesh.CreatePlane("TextPlane", size, scene, true);
        plane.material = new StandardMaterial("TextPlaneMaterial", scene);
        plane.material.backFaceCulling = false;
        plane.material.specularColor = new Color3(1, 1, 1);
        plane.material.diffuseTexture = dynamicTexture;
        return plane;
    };

    let axisX = axis_arrow("X", size, new Color3(1, 0, 0), scene);
    let axisY = axis_arrow("Y", size, new Color3(0, 1, 0), scene);
    let axisZ = axis_arrow("Z", size, new Color3(0, 0, 1), scene);

    var xChar = makeTextPlane("X", "red", size / 10);
    xChar.position = new Vector3(0.9 * size, -0.05 * size, 0);

    var yChar = makeTextPlane("Y", "green", size / 10);
    yChar.position = new Vector3(0, 0.9 * size, -0.05 * size);

    var zChar = makeTextPlane("Z", "blue", size / 10);
    zChar.position = new Vector3(0, 0.05 * size, 0.9 * size);

    var hl = new HighlightLayer("hl1", scene);
    hl.intensity = 10;
    hl.addMesh(xChar, Color3.Red());
    hl.addMesh(yChar, Color3.Green());
    hl.addMesh(zChar, Color3.Blue());
    hl.addMesh(axisX, Color3.Red());
    hl.addMesh(axisY, Color3.Green());
    hl.addMesh(axisZ, Color3.Blue());
};

//Local Axes
export function localAxes(size, scene) {
    let local_axisX = axis_arrow("X", size, new Color3(1, 0, 0), scene);
    let local_axisY = axis_arrow("Y", size, new Color3(0, 1, 0), scene);
    let local_axisZ = axis_arrow("Z", size, new Color3(0, 0, 1), scene);

    var local_origin = MeshBuilder.CreateBox("local_origin", { size: 1 }, scene);
    local_origin.isVisible = false;
    local_axisX.parent = local_origin;
    local_axisY.parent = local_origin;
    local_axisZ.parent = local_origin;

    return local_origin;
};

export function addEarth(scene) {
    var earth_root = new TransformNode();

    var earth = MeshBuilder.CreateSphere("earth", { diameter: 2 }, scene);

    var earthMaterial = new StandardMaterial("ground", scene);
    earthMaterial.diffuseTexture = new Texture("https://ephemersys-mvp-media-dev.s3-eu-west-1.amazonaws.com/modelling/earth.jpg", scene);
    earthMaterial.diffuseTexture.uScale = -1;

    earth.material = earthMaterial;
    earth.addRotation(-Math.PI/2, 0, 0).addRotation(0, Math.PI, 0);

    earth.parent = earth_root;
    
    return earth_root;
}

export function addSky(scene) {
    var sky = MeshBuilder.CreateSphere("sky", { diameter: 1000.0 }, scene);
    sky.infiniteDistance = true;
    sky.renderingGroupId = 0;

    var skyMaterial = new StandardMaterial("skyMaterial", scene);
    skyMaterial.backFaceCulling = false;
    skyMaterial.diffuseTexture = new Texture("https://ephemersys-mvp-media-dev.s3-eu-west-1.amazonaws.com/modelling/sky.jpg", scene);
    // skyMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    skyMaterial.diffuseColor = new Color3(0.3, 0.3, 0.3);
    skyMaterial.specularColor = new Color3(0, 0, 0);
    skyMaterial.diffuseTexture.vScale = 1;
    // skyMaterial.diffuseTexture.uOffset = .5;
    sky.material = skyMaterial;

    // skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("textures/skybox", scene);
}
