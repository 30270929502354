import React from "react";
import Box from '@mui/material/Box';
// import "./NotFound.css";

export default function NotFound() {
  return (
    <Box className="NotFound" sx={{paddingTop: 10, paddingBottom: 10}}>
      <h3>Sorry, page not found!</h3>
    </Box>
  );
}
