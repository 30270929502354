import { 
    eciToGeodetic, 
    gstime
} from 'satellite.js';
import solarxyz from 'astronomia/solarxyz';
import planetposition from 'astronomia/planetposition';
import julian from 'astronomia/julian';
import astroEarthData from 'astronomia/data/vsop87Bearth';

function calcSunPosition(timeStamp) {
    const d = new Date(timeStamp);
    const jde = julian.DateToJD(d);
    const earthData = new planetposition.Planet(astroEarthData);
    let sunPosition = solarxyz.position(earthData, jde);
    return [sunPosition.x, sunPosition.y, sunPosition.z, ];
}

export function calcSunPositions(timeStamps) {
    let pos = timeStamps.map( timeStamp => calcSunPosition(timeStamp) );
    return {error: 0, payload: {positionEci: pos}};
}

function calcEarthOrientation(timeStamp) {
    var gmst = gstime(new Date(timeStamp));
    let positionGd = eciToGeodetic({x: 1, y: 0, z: 0}, gmst);
    let rotAngle = -positionGd.longitude;
    return [0, rotAngle, 0];
}

export function calcEarthOrientations(timeStamps){
    let ori = timeStamps.map( timeStamp => calcEarthOrientation(timeStamp) );
    return {error: 0, payload: {orientation: ori}};
}

export function getTimeList(startTimeStr, endTimeStr, timeResolution) {
    let startTime = new Date(startTimeStr);
    let endTime = new Date(endTimeStr);
    let timeStamp = startTime;
    let maxSeconds = Math.floor( (endTime - startTime) / 1000 );
    var timeList = [];

    for (let seconds = 0; seconds <= maxSeconds; seconds += timeResolution) {
        timeStamp.setSeconds(timeStamp.getSeconds() + timeResolution);
        timeList.push(timeStamp.toUTCString());
    }
    return timeList;
}
