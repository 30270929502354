import {
    BODY_X,
    BODY_Y,
    BODY_Z,
    SUN,
    VELOCITY_ECI,
    NADIR,
    ZENITH,
    ORBIT_NORMAL,
    ORBIT_RADIAL,
    // VELOCITY_ECEF,
} from '../containers/utils/satAttitudes';

import {
    Vector3, 
    Path3D,
    Quaternion,
    Matrix,
} from '@babylonjs/core';


const RADIUS_OF_EARTH = 6371; // Radius of Earth in kilometers
const TAU = 2 * Math.PI;
const RAD2DEG = 360 / TAU;
const RADIUS_OF_SUN = 695700; // Radius of the Sun in kilometers
const DISTANCE_TO_SUN = 149597870; // Distance from Earth to the Sun in kilometers

function dotVV(x,y) {
    let i,n=x.length,i1,ret = x[n-1]*y[n-1];
    for(i=n-2;i>=1;i-=2) {
        i1 = i-1;
        ret += x[i]*y[i] + x[i1]*y[i1];
    }
    if(i===0) { ret += x[0]*y[0]; }
    return ret;
}

function sunStatus(sunECIones, satECI) {
    const sunECI = sunECIones.map(k => k * DISTANCE_TO_SUN)
    // TODO: Implement enums for the return values
    // if (typeof satSet.satData[i].position == 'undefined') return SunStatus.UNKNOWN;

    // Distances all in km
    // satSet.sunECI is updated by drawManager every draw frame
    // const sunECI = satSet.sunECI;

    // NOTE: Code is mashed to save memory when used on the whole catalog
    // Position needs to be relative to satellite NOT ECI
    // var distSatEarthX = Math.pow(-satSet.satData[i].position.x, 2);
    // var distSatEarthY = Math.pow(-satSet.satData[i].position.y, 2);
    // var distSatEarthZ = Math.pow(-satSet.satData[i].position.z, 2);
    // var distSatEarth = Math.sqrt(distSatEarthX + distSatEarthY + distSatEarthZ);
    // var semiDiamEarth = Math.asin(RADIUS_OF_EARTH/distSatEarth) * RAD2DEG;

    const semiDiamEarth =
      Math.asin(
        RADIUS_OF_EARTH / Math.sqrt(Math.pow(-satECI[0], 2) + Math.pow(-satECI[1], 2) + Math.pow(-satECI[2], 2))
      ) * RAD2DEG;

    // Position needs to be relative to satellite NOT ECI
    // var distSatSunX = Math.pow(-satSet.satData[i].position.x + sunECI.x, 2);
    // var distSatSunY = Math.pow(-satSet.satData[i].position.y + sunECI.y, 2);
    // var distSatSunZ = Math.pow(-satSet.satData[i].position.z + sunECI.z, 2);
    // var distSatSun = Math.sqrt(distSatSunX + distSatSunY + distSatSunZ);
    // var semiDiamSun = Math.asin(RADIUS_OF_SUN/distSatSun) * RAD2DEG;
    const semiDiamSun =
      Math.asin(
        RADIUS_OF_SUN /
          Math.sqrt(
            Math.pow(-satECI[0] + sunECI[0], 2) + Math.pow(-satECI[1] + sunECI[1], 2) + Math.pow(-satECI[2] + sunECI[2], 2)
          )
      ) * RAD2DEG;

    // Angle between earth and sun
    const theta =
      Math.acos(
        (
            dotVV(
            [-satECI[0], -satECI[1], -satECI[2]],
            [-satECI[0] + sunECI[0], -satECI[1] + sunECI[1], -satECI[2] + sunECI[2]]
          )
        ) /
          (Math.sqrt(Math.pow(-satECI[0], 2) + Math.pow(-satECI[1], 2) + Math.pow(-satECI[2], 2)) *
            Math.sqrt(
                Math.pow(-satECI[0] + sunECI[0], 2) +
                Math.pow(-satECI[1] + sunECI[1], 2) +
                Math.pow(-satECI[2] + sunECI[2], 2)
            ))
      ) * RAD2DEG;
    
    if (semiDiamEarth > semiDiamSun && theta < semiDiamEarth - semiDiamSun) {        
      return "UMBRAL";
    }

    if ((semiDiamSun > semiDiamEarth) || 
        (theta < semiDiamSun - semiDiamEarth) || 
        (Math.abs(semiDiamEarth - semiDiamSun) < theta && theta < semiDiamEarth + semiDiamSun)) {
      return "PENUMBRAL";
    }

    return "SUN";
};



export const sunStatusTS = (satEciTs, sunEciTs) => {
    return new Promise(
        (resolve, reject) => {
            let statusTs = []
            for (let i = 0; i < satEciTs.length; i = i+1){
                let currentStatus = sunStatus(sunEciTs[i], satEciTs[i]);
                statusTs = statusTs.concat([currentStatus]);
            }
            statusTs.length > 0 ? resolve(statusTs) : reject(["Error during calculation!"])
        }
    )
}

function rotateVector(vect, quat) {
    var matr = new Matrix();
    quat.toRotationMatrix(matr);
    var rotatedvect = Vector3.TransformCoordinates(vect, matr);
    return rotatedvect;
}

function calculateSatSunVector(satEciTs, sunEciTsOnes, attitude) {
    const sunEciTs = sunEciTsOnes.map(i => i.map(k => k * DISTANCE_TO_SUN));

    const orbitPoints = satEciTs.map(position => new Vector3(
        position[0], 
        position[1], 
        position[2]
    ));

    let nadirVectors = orbitPoints.map((orbitalPoint) => (new Vector3(0,0,0)).subtract(orbitalPoint));
    let zenithVectors = orbitPoints;

    let sunVectors = orbitPoints.map((orbitalPoint, index) => (new Vector3(
        sunEciTs[index][0], 
        sunEciTs[index][1], 
        sunEciTs[index][2])).subtract(orbitalPoint));

    sunVectors = sunVectors.map(vec3 => vec3.normalizeToNew())
    
    let path3d = new Path3D(orbitPoints, nadirVectors[0]);
    let tangents = path3d.getTangents();  //array of tangents to the curve
    let normals = path3d.getNormals(); //array of normals to the curve
    let binormals = path3d.getBinormals(); //array of binormals to curve

    let velocityEciVectors = tangents;
    let orbitNormalVectors = binormals;
    let orbitRadials = normals.map((normal, index) => rotateVector(normal, new Quaternion.RotationAxis(tangents[index], Math.PI))); 
    
    // Vectors in Satellite Centered Inertial (This is ECI shifted to Satellite's (0,0,0))
    // let sunVector = [sunEci[0] - satEci[0], sunEci[1] - satEci[1], sunEci[2] - satEci[2]];
    // let nadirVector = [-satEci[0], -satEci[1], -satEci[2]];
    // let zenithVector = [satEci[0], satEci[1], satEci[2]];
    // let velocityEciVector
    // let orbitNormalVector
    // let orbitRadial
    // let tangent

    const getAlignmentVectors = () => {
        switch (attitude.alignment.alignmentVector) {
          case SUN: {
            return sunVectors;
          }
          case VELOCITY_ECI: {
            return velocityEciVectors;
          }
          case NADIR: {
            return nadirVectors;
          }
          case ZENITH: {
            return zenithVectors;
          }
          case ORBIT_NORMAL: {
            return orbitNormalVectors;
          }
          case ORBIT_RADIAL: {
            return orbitRadials;
          }
          default: {
            return tangents
          }
        }
    }
    
    const getConstraintVectors = () => {
        switch (attitude.constraint.constraintVector) {
          case SUN: {
            return sunVectors;
          }
          case VELOCITY_ECI: {
            return velocityEciVectors;
          }
          case NADIR: {
            return nadirVectors;
          }
          case ZENITH: {
            return zenithVectors;
          }
          case ORBIT_NORMAL: {
            return orbitNormalVectors;
          }
          case ORBIT_RADIAL: {
            return orbitRadials;
          }
          default: {
            return zenithVectors
          }
        }
    }

    let axis1 = getAlignmentVectors();
    let constraintVectors = getConstraintVectors();
    let axis3 = axis1.map((axis_vector, index) => Vector3.Cross(axis_vector, constraintVectors[index]));
    let axis2 = axis1.map((axis_vector, index) => Vector3.Cross(axis3[index], axis_vector));
  
    const getRoations = () => {
    switch (attitude.alignment.satVector + attitude.constraint.satVector) {
        case (BODY_X + BODY_Y): {
          return axis1.map((axis, index) => new Vector3.RotationFromAxis(axis, axis2[index], axis3[index]));
        }
        case (BODY_X + BODY_Z): {
          return axis1.map((axis, index) => {
            axis3[index] = rotateVector(axis3[index], new Quaternion.RotationAxis(axis, Math.PI));
            return new Vector3.RotationFromAxis(axis, axis3[index], axis2[index])});
        }
        case (BODY_Y + BODY_X): {
          return axis1.map((axis, index) => {
            axis3[index] = rotateVector(axis3[index], new Quaternion.RotationAxis(axis, Math.PI));
            return new Vector3.RotationFromAxis(axis2[index], axis, axis3[index])});
        }
        case (BODY_Y + BODY_Z): {
          return axis1.map((axis, index) => new Vector3.RotationFromAxis(axis3[index], axis, axis2[index]));
        }
        case (BODY_Z + BODY_X): {
          return axis1.map((axis, index) => new Vector3.RotationFromAxis(axis2[index], axis3[index], axis));
        }
        case (BODY_Z + BODY_Y): {
          return axis1.map((axis, index) => {
            axis3[index] = rotateVector(axis3[index], new Quaternion.RotationAxis(axis, Math.PI))
            return new Vector3.RotationFromAxis(axis3[index], axis2[index], axis)});
        }
        default: {
          return axis1.map((axis, index) => new Vector3.RotationFromAxis(axis, axis2[index], axis3[index]));
        }
      }
    }
    let rotations = getRoations();
    const vecs = sunVectors;
    const quats = rotations.map((rot) => new Quaternion.FromEulerAngles(rot.x, rot.y, rot.z));
    const seunVecsfromSat = vecs.map((vec, index) => {
        let vecZero = new Vector3.Zero();
        vec.rotateByQuaternionToRef(quats[index], vecZero)
        return vecZero
    });
    let alphaBeta = seunVecsfromSat.map(v => [Math.asin(v.z / v.length())*RAD2DEG, Math.atan2(v.x, v.y)*RAD2DEG])
    alphaBeta = alphaBeta.map(v => {
        let beta = v[1] < 0 ? 360+v[1] : v[1]
        return([v[0], beta])
    })
    return alphaBeta;
}

export function calculateSunVector(satEciTs, sunEciTsOnes, attitude) {
    // console.log(satEciTs, sunEciTsOnes, attitude);
    return new Promise(
        (resolve, reject) => {
            let rot = calculateSatSunVector(satEciTs, sunEciTsOnes, attitude)
            true ? resolve(rot) : reject(["Error during calculation!"])
        }
    )
}

function incomingPower(satAlphaBetaTs, satPowerMap, sunStatusTs) {
    const incomingPowerTs = satAlphaBetaTs.map((ab, index) => {
        const [alpha, beta] = ab;

        let x0 = Math.floor(beta / 5) * 5;
        let x1 = Math.ceil(beta / 5) * 5;
        let y0 = Math.floor(alpha / 5) * 5;
        let y1 = Math.ceil(alpha / 5) * 5;
        if (x1 === 360) {
            x1 = 0
        }

        const q00 = satPowerMap.filter(l1 => l1[1] === x0).filter(l2 => l2[0] === y0)[0][2]
        const q01 = satPowerMap.filter(l1 => l1[1] === x0).filter(l2 => l2[0] === y1)[0][2]
        const q10 = satPowerMap.filter(l1 => l1[1] === x1).filter(l2 => l2[0] === y0)[0][2]
        const q11 = satPowerMap.filter(l1 => l1[1] === x1).filter(l2 => l2[0] === y1)[0][2]
        const interpolatedValue = interpolate2D(q00, q01, q10, q11, beta/360, (alpha+90)/180)
        return sunStatusTs[index] === 'SUN' ? interpolatedValue : 0

        // const closestAlpha = satPowerMap.map(x => x[0]).reduce((prev, curr) => {
        //     return (Math.abs(curr - alpha) < Math.abs(prev - alpha) ? curr : prev);
        // });
        // const closestBeta = satPowerMap.map(x => x[1]).reduce((prev, curr) => {
        //     return (Math.abs(curr - beta) < Math.abs(prev - beta) ? curr : prev);
        // });
        // const powValue = satPowerMap.filter(pow => pow[0] === closestAlpha && pow[1] === closestBeta)
        // return sunStatusTs[index] === 'SUN' ? powValue[0][2] : 0
    })
    return incomingPowerTs
}

export function calculateIncomingPowerTs(satAlphaBetaTs, satPowerMap, sunStatusTs) {
    return new Promise(
        (resolve, reject) => {
            let res = incomingPower(satAlphaBetaTs, satPowerMap, sunStatusTs)
            true ? resolve(res) : reject(["Error during calculation!"])
        }
    )
}

function interpolate2D(q00, q01, q10, q11, x, y) {
  const bilinearInterpolator = func => (x, y) => {
    // "func" is a function that takes 2 integer arguments and returns some value
    const x1 = Math.floor(x);
    const x2 = Math.ceil(x);
    const y1 = Math.floor(y);
    const y2 = Math.ceil(y);
  
    if ((x1 === x2) && (y1 === y2)) return func(x1, y1);
    if (x1 === x2) {
      return (func(x1, y1) * (y2 - y) + func(x1, y2) * (y - y1)) / (y2 - y1);
    }
    if (y1 === y2) {
      return (func(x1, y1) * (x2 - x) + func(x2, y1) * (x - x1)) / (x2 - x1);
    }
  
    // else: x1 != x2 and y1 != y2
    return (
      func(x1, y1) * (x2 - x) * (y2 - y) +
      func(x2, y1) * (x - x1) * (y2 - y) +
      func(x1, y2) * (x2 - x) * (y - y1) +
      func(x2, y2) * (x - x1) * (y - y1)
    )
    / ((x2 - x1) * (y2 - y1));
  }
  
  const sampleFunc = (x, y) => {
    if ((x === 0) && (y === 0)) return q00;
    if ((x === 0) && (y === 1)) return q01;
    if ((x === 1) && (y === 0)) return q10;
    if ((x === 1) && (y === 1)) return q11;
  }

  const interpolate = bilinearInterpolator(sampleFunc);
  return interpolate(x, y);
}