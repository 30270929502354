import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TimeSeriesChart from './TimeSeriesChart';
import Button from '@mui/material/Button';
import dataObjectToCsv from './utils/dataExport';
import Box from '@mui/material/Box';
// import "./Reports.css";

const prepareData = (predictions) => {
  let data = predictions.timestemps.map((timeStamp, index) => ({
      timeStamp: new Date(timeStamp),
      groundPositionLat: predictions.groundtrack.flat(1)[index][0],
      groundPositionLon: predictions.groundtrack.flat(1)[index][1],
      positionsEciX: predictions.positionsEci[index][0],
      positionsEciY: predictions.positionsEci[index][1],
      positionsEciZ: predictions.positionsEci[index][2],
      orientationEciAlpha: predictions.orientationEci[index][0],
      orientationEciBeta: predictions.orientationEci[index][1],
      orientationEciGamma: predictions.orientationEci[index][2],
  }));
  return data;
};

function Lifetime(props) {
  const [chartData, setChartData] = useState();
  useEffect(() => {
    const data22 = prepareData({
      timestemps: props.timestemps, 
      groundtrack: props.groundtrack,
      positionsEci: props.positionsEci,
      orientationEci: props.orientationEci});
    if (data22.length > 0){
      setChartData(data22);
    } else if (data22.length === 0) {
      setChartData([]);
    }
  }, [props.groundtrack, props.orientationEci, props.positionsEci, props.timestemps]);

  const getNoDataOrLoading2 = () => {
    if (chartData === undefined){ 
      return "Loading...";
    } else if (chartData.length === 0) {
      return "No Data";
    } else {
      return(<TimeSeriesChart data={props}/>);
    }
  }

  const handleSaveCsv = () => {
    // dataArrayToCsv(['Lat', 'Lon'], props.groundtrack.flat(1), ',', 'test_save')
    dataObjectToCsv(
      ['Time', 'Lat', 'Lon', 'ECI x', 'ECI y', 'ECI z', 'alpha', 'beta', 'gamma'], 
      chartData, 
      ',', 
      'scenario_data'
    )
  }

  return (
    <Box className="Reports" sx={{paddingTop: 10, paddingBottom: 10}}>
      <h3>Reports Page !</h3>

      <Button 
        variant="outlined" 
        color="primary"
        onClick={handleSaveCsv}
      >
        Save Data as CSV
      </Button>

      <br /><br />
      {getNoDataOrLoading2()}
    </Box>
  );
}

const mapStateToProps = state => {
  return { 
    timestemps: state.scenarios[state.currentScenarioKey].analytics.predictions.satPos.timeStemps, 
    groundtrack: state.scenarios[state.currentScenarioKey].analytics.predictions.satPos.groundTrack, 
    positionsEci: state.scenarios[state.currentScenarioKey].analytics.predictions.satPos.positionsEci, 
    orientationEci: state.scenarios[state.currentScenarioKey].analytics.predictions.satPos.orientationEci,
  };
};

export default connect(mapStateToProps)(Lifetime)