import { SET_CURRENTSCENARIOKEY } from '../actionTypes';

const initalState = '';

export default function setCurrentScenarioKeyAction(state = initalState, action) {
    switch (action.type) {
        case SET_CURRENTSCENARIOKEY: {
            return action.payload ? action.payload.scenarioKey : initalState;
        }
        default:
            return state;
    }
}