import { propagateTle, propageteKepler } from './propagator';
import { calcSunPositions, calcEarthOrientations } from './solarSystem';

export const propageteKeplerP = (kepler) => {
    return new Promise(
        (resolve, reject) => {
            let trajectory = propageteKepler(kepler);
            trajectory.error > 0 ? reject(trajectory.payload) : resolve(trajectory.payload);
        }
    );
};

export const propagateTleP = (tle) => {
    return new Promise(
        (resolve, reject) => {
            let trajectory = propagateTle(tle);
            trajectory.error > 0 ? reject(trajectory.payload) : resolve(trajectory.payload);
        }
    );
};

export const calcSunPositionsP = (timeStamps) => {
    return new Promise(
        (resolve, reject) => {
            let sunPositions = calcSunPositions(timeStamps);
            sunPositions.error > 0 ? reject(sunPositions.payload) : resolve(sunPositions.payload); 
        }
    );
};

export const calcEarthOrientationsP = (timeStamps) => {
    return new Promise(
        (resolve, reject) => {
            let earthOrientations = calcEarthOrientations(timeStamps);
            earthOrientations.error > 0 ? reject(earthOrientations.payload) : resolve(earthOrientations.payload); 
        }
    );
};