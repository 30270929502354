import React from "react";
import GroundTrack from './GroundTrack';
import ThreeDview from './ThreeDview';
import Grid from '@mui/material/Grid';
import TimeSlider from './TimeSlider';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import MapIcon from '@mui/icons-material/Map';
import Paper from '@mui/material/Paper';


export default function OrbitPrediction() {
  const [threeD, setThreeD] = React.useState(false);

  const render2D = () => {
    return(
      <Grid container spacing={0} sx={{ height: 'inherit', width: '100%' }}>
        <Grid item xs={12}>
          <GroundTrack />
        </Grid>
      </Grid>
    )
  }

  const render3D = () => {
    return(
      <Grid container spacing={0} >
        <Grid item xs={12}>
          <ThreeDview />
        </Grid>
      </Grid>
    )
  }

  return (
      <>
        <Box
          sx={{ position: 'absolute', 
            bottom: 15,
            left: 15,
            zIndex: 10, 
          }}
        >
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              border: (theme) => `1px solid ${theme.palette.divider}`,
              backgroundColor: 'rgba(255,255,255,0.4)',
              flexWrap: 'wrap',
            }}
          >
            <ToggleButton
              size="small"
              value="2D/3D"
              selected={false}
              onChange={() => {
                setThreeD(!threeD);
              }}
            >
              {threeD ? <MapIcon /> : <ThreeDRotationIcon /> }
            </ToggleButton>
          </Paper>
        </Box>
        <TimeSlider />
      {threeD ? render3D() : render2D()}
      </>
  );
}
