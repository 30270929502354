export const SET_TLE = "SET_TLE";
export const SET_SATTRACK = "SET_SATTRACK";
export const SET_SATPOSITION = "SET_SATPOSITION";
export const SET_TLE_TIMES = "SET_TLE_TIMES";
export const SET_SHOWTIMERANGE = "SET_SHOWTIMERANGE";
export const SET_KEPLER = "SET_KEPLER";
export const SET_EARTH = "SET_EARTH";
export const SET_SUN = "SET_SUN";
export const SET_MOON = "SET_MOON";
export const SET_SATATTITUDE = "SET_SATATTITUDE";
export const ADD_NEWSCENARIO = "ADD_NEWSCENARIO";
export const INIT_NEWSCENARIO = "INIT_NEWSCENARIO";
export const SET_CURRENTSCENARIOKEY = "SET_CURRENTSCENARIOKEY";
export const SET_SATCONFIG = "SET_SATCONFIG";
export const SET_ANALYTICSDONE = "SET_ANALYTICSDONE";
export const SET_ORBITTYPE = "SET_ORBITTYPE";
export const IFINIT_ANALYTICSSTATUS = "IFINIT_ANALYTICSSTATUS";
export const SET_INCOMINPOWER = "SET_INCOMINPOWER";