import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { connect } from "react-redux";
import { setSateConfig } from "../redux/actions";
import { getSatelliteByScenarioId, listAllDemoSatellite, updateSatOfScenario } from "./utils/scenarioQery";


function SatDesign(props) {
  const [isLoading, setLoading] = useState(true);
  const [satCatalog, setSatCatalog] = useState([]);
  const [satKey, setSatKey] = useState();
  const [isDemo, setDemo] = useState(false);

  useEffect(() => {
    listAllDemoSatellite()
    .then(satellites => {
      setSatCatalog(satellites);
      setLoading(false)
    })

    if (!props.newScenario) {
      getSatelliteByScenarioId(props.scenarioKey)
      .then(({demo, satellite}) => {
        setDemo(demo);
        setSatKey(satellite.satKey)
      })  
    }
    console.log('sat querry');
  }, [props.newScenario, props.scenarioKey]);

  const handleClick = (_event, key) => {
    if (props.newScenario){
      const sat = satCatalog.filter(item => item.satKey === key);
      props.setSateConfig(props.scenarioKey, {...sat[0], type: key})
    }
    if (!isDemo && !props.newScenario){
      updateSatOfScenario(key, props.scenarioKey)
    }
    if (!isDemo){
      setSatKey(key);
    }
  };

  return (
    <Box className="SatDesign" sx={{paddingTop: 10, paddingBottom: 10}}>
      <h3>Sat Design Page</h3>
      {isLoading ? <CircularProgress /> : (
        <Container maxWidth='xl'>
          <Grid container spacing={3}>
          {satCatalog.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.satKey}>
            <Card 
              sx={
                // props.satConfig.type === item.satKey
                satKey === item.satKey 
                ? { outline: '6px ridge rgba(100, 100, 255, 0.7)' }
                : { outline: '6px ridge rgba(100, 100, 255, 0)' }
              }
            >
              <CardActionArea 
                onClick={e => handleClick(e, item.satKey)}
              >
                <CardMedia
                  component={item.mediaType}
                  src={item.mediaUrl}
                  title={item.name}
                  onMouseOver={e => (item.mediaType==='video') && e.target.play()}
                  onMouseOut={e => (item.mediaType==='video') && e.target.pause()}
                  loop
                  muted
                />
                <CardContent>
                  <Typography 
                    gutterBottom 
                    variant="h5" 
                    component="h2"
                  >
                    {item.name}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    component="p"
                  >
                    Size: {item.size}
                    <br />
                    Wings: {item.wings}
                    <br />
                    Area of Solar cells: {item.solarArea}
                    <br />
                    Number of Solar cells: {item.solarCellNr}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            </Grid>
          ))}
          </Grid>
        </Container>
      )}
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    scenarioKey: state.currentScenarioKey,
    // newSatConfig: state.scenarios["new-scenario"].definitions.satellite
    // satConfig: state.scenarios[state.currentScenarioKey].definitions.satellite,
  }
}

export default connect(
  mapStateToProps,
  { setSateConfig, }
)(SatDesign);