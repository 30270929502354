import React, { useEffect } from "react";
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { Button, CircularProgress } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { connect } from "react-redux";
import { setSatAttitude } from "../redux/actions";
import {
    BODY_X,
    BODY_Y,
    BODY_Z,
    SUN,
    VELOCITY_ECI,
    NADIR,
    ZENITH,
    ORBIT_NORMAL,
    ORBIT_RADIAL,
    // VELOCITY_ECEF,
} from './utils/satAttitudes';
// import "./AttitudeProfile.css";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { Container } from "@mui/material";
import { getAttitudeOfSat, updateAttitudeOfSat } from "./utils/scenarioQery";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function AttitudeProfile(props) {
    const [errMsg, setErrMsg] = React.useState({ 
        error: false, 
        msg: 'Alignment settings good.', 
    })
    const [openErr, setOpenErr] = React.useState(false);
    const [state, setState] = React.useState({
        isRotating: false,
        rotation: {
          bodyX: 1,
          bodyY: 0,
          bodyZ: 0,
          rotationSpeed: 0.2,
        },
        alignment: {
            satVector: BODY_Z,
            alignmentVector: NADIR,
        },
        constraint: {
            satVector: BODY_Y,
            constraintVector: SUN,
        },
    });
    const [isDemo, setDemo] = React.useState(false)
    const [isSaving, setSaving] = React.useState(false);

    useEffect(() => {
        if (props.scenarioKey !== 'new-scenario') {
            getAttitudeOfSat(props.scenarioKey)
            .then(({demo, attitude}) => {
                setDemo(demo)
                setState(old => ({
                    ...old, 
                    isRotating: attitude.rotation,
                    rotation: {
                        bodyX: attitude.rotationAxisX,
                        bodyY: attitude.rotationAxisY,
                        bodyZ: attitude.rotationAxisZ,
                        rotationSpeed: attitude.rotationSpeed,
                    },
                    alignment: {
                        satVector: attitude.alignmentSatVector,
                        alignmentVector: attitude.alignmentVector,
                    },
                    constraint: {
                        satVector: attitude.constraintSatVector,
                        constraintVector: attitude.constraintVector,
                    },
                }))
            })
        }
        console.log('puff');
    }, [props.scenarioKey])

    const handleSave = () => {
        setSaving(true)
        updateAttitudeOfSat(props.scenarioKey, {
            alignmentSatVector: state.alignment.satVector,
            alignmentVector: state.alignment.alignmentVector,
            constraintSatVector: state.constraint.satVector,
            constraintVector: state.constraint.constraintVector,
            rotation: state.isRotating,
            rotationAxisX: state.rotation.bodyX,
            rotationAxisY: state.rotation.bodyY,
            rotationAxisZ: state.rotation.bodyZ,
            rotationSpeed: state.rotation.rotationSpeed,
        })
        .then(setSaving(false))
      }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenErr(false);
    };
    
    const validateAttitudeForm = (formState) => {
        if (formState.alignment.satVector === formState.constraint.satVector){
            return { error: true, msg: 'ERROR: The alignment and the constarint Body vectors cannot be the same.', };
        }
        if (formState.alignment.alignmentVector === formState.constraint.constraintVector){
            return { error: true, msg: 'ERROR: The alignment and the constarint Reference vectors cannot be the same.', };
        }
        return { error: false, msg: 'Alignment settings done.', };
    }

    const handleAlignmentChange = (event) => {
        const [name1, name2] = event.target.name.split('.')
        const toSet = {
            [name1]: {
                ...state[name1],
                [name2]: event.target.value,
            },
        }
        let valid = validateAttitudeForm({
            ...state,
            ...toSet,
        });
        setErrMsg(valid);        
        if (!valid.error) {
            setState({
                ...state,
                ...toSet,
            });
            if (props.scenarioKey === 'new-scenario') {
                props.setSatAttitude(props.scenarioKey, {
                    ...state,
                    ...toSet,
                });
            }
        } else {
            setOpenErr(true);
        }

    };

    const handleRotationChange = (event) => {
        const toState = {
            [event.target.name]: event.target.checked,
        }
        setState({
            ...state,
            ...toState,
        })
        if (props.scenarioKey === 'new-scenario') {
            props.setSatAttitude(props.scenarioKey, {
                ...state,
                ...toState,
            });
        }
    }

    const handleRotationFields = event => {
        const toState = {
            rotation: {
                ...state.rotation,
                [event.target.name]: Number(event.target.value),
            }
        }
        setState(oldstate => ({
            ...oldstate,
            ...toState,
        }))
        if (props.scenarioKey === 'new-scenario') {
            props.setSatAttitude(props.scenarioKey, {
                ...state,
                ...toState,
            });
        }
    }

    const renderRotationForm = () => {
        return(
            <>
                <h3>Rotation around an arbitrary axis</h3>            
                <h4>Set axis</h4>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '13ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        id="standard-number"
                        size="small"
                        label="Body X"
                        type="number"
                        name="bodyX"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={state.rotation.bodyX}
                        onChange={handleRotationFields}
                        disabled={isDemo}
                    />

                    <TextField
                        id="standard-number"
                        size="small"
                        label="Body Y"
                        type="number"
                        name="bodyY"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={state.rotation.bodyY}
                        onChange={handleRotationFields}
                        disabled={isDemo}
                    />

                    <TextField
                        id="standard-number"
                        size="small"
                        label="Body Z"
                        type="number"
                        name="bodyZ"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={state.rotation.bodyZ}
                        onChange={handleRotationFields}
                        disabled={isDemo}
                    />
                </Box>
                <h4>Set rotation</h4>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '13ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Box component="div" pb={2}>
                        <TextField
                            id="standard-number"
                            size="small"
                            label="Rotation"
                            type="number"
                            name="rotationSpeed"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText="revolution / sec"
                            value={state.rotation.rotationSpeed}
                            onChange={handleRotationFields}
                            disabled={isDemo}
                        />
                    </Box>
                </Box>
            </>
        )
    }

    const renderAlignmentForm = () => {
        return(
            <>
                <h3>Alignment</h3>
                <Grid container spacing={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    p={1}
                    m={1}
                >

                    <Grid item component="div">
                        The Satellite's
                    </Grid>

                    <Grid item component="div">
                        <FormControl 
                            size="small"
                            sx={{
                                margin: 1,
                                minWidth: 100,
                            }}
                        >
                            <InputLabel id="aligned-body-vector-label">Body</InputLabel>
                            <Select
                                labelId="aligned-body-vector-label"
                                label="Body"
                                value={state.alignment.satVector}
                                onChange={handleAlignmentChange}
                                inputProps={{
                                    name: 'alignment.satVector',
                                    id: 'aligned-body-vector',
                                }}
                                disabled={isDemo}
                            >
                                <MenuItem value={BODY_X}>X</MenuItem>
                                <MenuItem value={BODY_Y}>Y</MenuItem>
                                <MenuItem value={BODY_Z}>Z</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    
                    <Grid item component="div">
                        Vector aligned with
                    </Grid>

                    <Grid item component="div">
                        <FormControl 
                            size="small"
                            sx={{
                                margin: 1,
                                minWidth: 100,
                            }}
                        >
                            <InputLabel htmlFor="alignment-reference-vector-label">Reference</InputLabel>
                            <Select
                                labelId="alignment-reference-vector-label"
                                label="Reference"
                                value={state.alignment.alignmentVector}
                                onChange={handleAlignmentChange}
                                inputProps={{
                                    name: 'alignment.alignmentVector',
                                    id: 'alignment-reference-vector',
                                }}
                                disabled={isDemo}
                            >
                                <MenuItem value={NADIR}>Nadir</MenuItem>
                                <MenuItem value={ZENITH}>Zenit</MenuItem>
                                <MenuItem value={VELOCITY_ECI}>ECI Velocity</MenuItem>
                                <MenuItem value={ORBIT_NORMAL}>Orbit Normal</MenuItem>
                                <MenuItem value={ORBIT_RADIAL}>Orbit Radial</MenuItem>
                                <MenuItem value={SUN}>Sun</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item component="div" >
                        Vector
                    </Grid>
                </Grid>

                <h3>Constraint</h3>

                <Grid container spacing={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    p={1}
                    m={1}
                >

                    <Grid item component="div">The Satellite</Grid>

                    <Grid item component="div">
                        <FormControl 
                            size="small"
                            sx={{
                                margin: 1,
                                minWidth: 100,
                            }}
                        >
                            <InputLabel htmlFor="constrained-body-vector-label">Body</InputLabel>
                            <Select
                                labelId="constrained-body-vector-label"
                                label="Body"
                                value={state.constraint.satVector}
                                onChange={handleAlignmentChange}
                                inputProps={{
                                    name: 'constraint.satVector',
                                    id: 'constrained-body-vector',
                                }}
                                disabled={isDemo}
                            >
                                <MenuItem value={BODY_X}>X</MenuItem>
                                <MenuItem value={BODY_Y}>Y</MenuItem>
                                <MenuItem value={BODY_Z}>Z</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item component="div">Vector constraint in the direction of</Grid>

                    <Grid item component="div">
                        <FormControl 
                            size="small"
                            sx={{
                                margin: 1,
                                minWidth: 100,
                            }}
                        >
                            <InputLabel htmlFor="constrain-reference-vector-label">Reference</InputLabel>
                            <Select
                                labelId="constrain-reference-vector-label"
                                label="Reference"
                                value={state.constraint.constraintVector}
                                onChange={handleAlignmentChange}
                                inputProps={{
                                    name: 'constraint.constraintVector',
                                    id: 'constrain-reference-vector',
                                }}
                                disabled={isDemo}
                            >
                                {/* <MenuItem value={VELOCITY_ECEF}>ECEF Velocity</MenuItem> */}
                                <MenuItem value={NADIR}>Nadir</MenuItem>
                                <MenuItem value={ZENITH}>Zenit</MenuItem>
                                <MenuItem value={VELOCITY_ECI}>ECI Velocity</MenuItem>
                                <MenuItem value={ORBIT_NORMAL}>Orbit Normal</MenuItem>
                                <MenuItem value={ORBIT_RADIAL}>Orbit Radial</MenuItem>
                                <MenuItem value={SUN}>Sun</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item component="div">Vector</Grid>
                </Grid>
            </>
        )
    }

    return (
        <Container maxWidth='xl' sx={{paddingTop: 10, paddingBottom: 10}}>
            <h2>Set satellite nominal attitude</h2>

            <Snackbar open={openErr} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
                    {errMsg.msg}
                </Alert>
            </Snackbar>

            <FormControlLabel
                control={
                <Checkbox
                    checked={state.isRotating}
                    onChange={handleRotationChange}
                    name="isRotating"
                    color="primary"
                    disabled={isDemo}
                />
                }
                label="Rotation"
            />
            {state.isRotating ? renderRotationForm() : renderAlignmentForm()}
            {(props.scenarioKey === 'new-scenario') ||
            <Button 
                variant="contained"
                onClick={handleSave}
                disabled={isSaving || isDemo}
            >
                {isSaving ? <CircularProgress /> : 'Save'}
            </Button>}
        </Container>
    );
}

const mapStateToProps = state => {
    return { scenarioKey: state.currentScenarioKey };
};

export default connect(
    mapStateToProps,
    { setSatAttitude }
  )(AttitudeProfile);