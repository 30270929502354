import { SET_ANALYTICSDONE, IFINIT_ANALYTICSSTATUS } from "../actionTypes";

const emptyState = {
    satOrbit: false,
    // solarSystem: false,
    radiation: false,
    incomingPower: false,
    lifetime: false,
    // satVisibility: false,
    rf: false,
    // data:false,
}

const initialState = {
    "235175ee-8518-4464-9018-73b259b0e7e5": {
        ...emptyState,
    }
}

export default function doneAnalyticsAction(state = initialState, action) {
    switch (action.type) {
        case IFINIT_ANALYTICSSTATUS: {
            const scenarioKey = action.payload.scenarioKey;
            return {
                ...state,
                [scenarioKey]: {
                    ...(state[scenarioKey] || emptyState),
                }
            }
        }
        case SET_ANALYTICSDONE: {
            const scenarioKey = action.payload.scenarioKey;
            return {
                ...state,
                [scenarioKey]: {
                    ...(state[scenarioKey] || emptyState),
                    ...action.payload.doneAnalytics,
                }
            }    
        }
        default:
            return state;
    }
}