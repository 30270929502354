import './App.css';
import AccountMenu from "./containers/menus/AccountMenu"
import HomeMenu from "./containers/menus/HomeMenu"
import AppRoutes from "./AppRoutes";

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


function App() {

  function renderDevInfo(){
    return(
      <div className='devinfo'>
        <b>COMMIT:</b> {process.env.REACT_APP_COMMIT} <b>STAGE:</b> {process.env.REACT_APP_STAGE} <b>NODE_ENV:</b> {process.env.NODE_ENV}
      </div>
    )
  }
  function renderC() {
    return (
      <div className='devinfo'>
        &copy; {new Date().getFullYear()} Ephemersys LLC
      </div>
    )
  }


  return (
    <Authenticator usernameAlias="email">
      {({ signOut, user }) => (
      <div className="App">
          <HomeMenu />
          <AccountMenu signOut={signOut} user={user} />
            <AppRoutes />
          {(process.env.REACT_APP_STAGE !== 'prod') ? renderDevInfo() : renderC()}
      </div>
      )}
    </Authenticator>
  );
}

export default App;
