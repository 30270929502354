import React from "react";
import ScenarioRender from './babylon/ScenarioRender';
import "./ThreeDview.css";

export default function ThreeDview() {
  return (
    <div className="ThreeDview">
      <ScenarioRender />
    </div>
  );
}
