import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { addNewScenario, setCurrentScenarioKey } from "../../redux/actions"


function NewScenarioCard(props) {
  const navigate = useNavigate();

  const handleClick = () => {
    const newSenarioKey = 'new-scenario';
    props.setCurrentScenarioKey(newSenarioKey);
    navigate('/new');
  }
  
  return (
    <Card sx={{ width: 230 }}>
      <CardMedia
        component="img"
        height="140"
        image="/logo280.png"
        alt="green iguana"
        sx={{
          opacity: 0.5,
        }}
      />
      <CardContent sx={{ height: 110 }}>
        <Typography gutterBottom variant="h5" component="div">
            New scenario
        </Typography>
        <Typography variant="body2" color="text.secondary">
            A wizzard helps you to crate a new scenario.
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={handleClick}>
            + Create
        </Button>
      </CardActions>
    </Card>
  );
}

const mapStateToProps = state => {
  return { nrOfScenarios: Object.keys(state.scenarios).length.toString() };
};

export default connect( mapStateToProps, { addNewScenario, setCurrentScenarioKey } )(NewScenarioCard);