import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import { connect } from 'react-redux';
import { setSatPosition, setShowTimerange } from "../redux/actions";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import PauseIcon from '@mui/icons-material/Pause';
import Stack from '@mui/material/Stack';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';


const TimeLineSlider = styled(Slider)`
  root: {
    color: '#3880ff',
    padding: '15px 0',
    marginLeft: '15px',
  },
  markLabel: {
    transform: 'translateX(0ch)',
  },
`;

let interval = null;

function TimeSlider(props) {
  const [playing, setPlaying] = useState(false);
  const [playingSpeed, setPlayingSpeed] = useState(1);
  const [showText, setShowText] = React.useState(true);

  const rangeMax = props.satTrack.timeStemps[0] ? (props.satTrack.timeStemps.length - 1) : 1;
  const setShowTimerange = props.setShowTimerange;

  useEffect(() => {
    setShowTimerange({
        showTiemrange: [0, rangeMax],
    });
  }, [setShowTimerange, rangeMax]);

  const handleSliderChange = (event, newValue) => {
      props.setSatPosition({
        index: newValue,
        timeStemp: props.satTrack.timeStemps[newValue],
        groundPosition: props.satTrack.groundTrack.flat(1)[newValue],
        positionEci: props.satTrack.positionsEci[newValue],
    })
  }
  const handleRangeChange = (event, newValue) => {
      props.setShowTimerange({
        showTiemrange: newValue,
      })
      if (newValue[0] > props.currentSatPos.index) {
        props.setSatPosition({
            index: newValue[0],
            timeStemp: props.satTrack.timeStemps[newValue[0]],
            groundPosition: props.satTrack.groundTrack.flat(1)[newValue[0]],
            positionEci: props.satTrack.positionsEci[newValue[0]],
        })
      } else if (newValue[1] < props.currentSatPos.index) {
        props.setSatPosition({
            index: newValue[1],
            timeStemp: props.satTrack.timeStemps[newValue[1]],
            groundPosition: props.satTrack.groundTrack.flat(1)[newValue[1]],
            positionEci: props.satTrack.positionsEci[newValue[1]],
        })
      }
  };

  const stepOnOrbit = (newValue) => {
    props.setSatPosition({
        index: newValue,
        timeStemp: props.satTrack.timeStemps[newValue],
        groundPosition: props.satTrack.groundTrack.flat(1)[newValue],
        positionEci: props.satTrack.positionsEci[newValue],
    });
  }

  const makeItMove = () => {
    let stepIndex = props.currentSatPos.index;
    let step = 1;
    if (playingSpeed < 0){
      step = -1
    }
    clearInterval(interval);
    interval = setInterval(() => {
      stepIndex = stepIndex + step;
      if (stepIndex >= 0 && stepIndex < props.satTrack.timeStemps.length) {
        stepOnOrbit(stepIndex);
      } else {
        clearInterval(interval);
        setPlaying(false);
      }
    }, 1000 / Math.abs(playingSpeed) );
  }

  const handlePlayingClick = () => {
    clearInterval(interval);
    if (!playing) {
      makeItMove();
    } 
    setPlaying(!playing);
  };

  const handlePlayingSpeedClick = (step) => {
    setPlayingSpeed(playingSpeed + step);
    if (playingSpeed === -1 && step === 1){
      setPlayingSpeed(1);
    } else if (playingSpeed === 1 && step === -1){
      setPlayingSpeed(-1);
    }
    if (playing) {
      makeItMove()
    } 
  }

  return (
    <div>
        <Stack
          sx={{ 
            position: 'absolute', 
            bottom: 80, 
            zIndex: 10, 
            paddingLeft: 4,
            paddingRight: 4,
            width: '100%',
          }}
        >
          <TimeLineSlider
            value={props.currentSatPos.showTiemrange}
            min={0}
            max={props.satTrack.timeStemps[0] ? (props.satTrack.timeStemps.length - 1) : 1}
            step={1}
            onChange={handleRangeChange}
            aria-labelledby="range-slider"
          />

          <TimeLineSlider
            value={typeof props.currentSatPos.index === 'number' ? props.currentSatPos.index : 0}
            aria-labelledby="time-slider"
            min={props.currentSatPos.showTiemrange[0]}
            max={props.currentSatPos.showTiemrange[1]}
            step={1}
            onChange={handleSliderChange}
          />
        </Stack>

          {showText &&
           <Box
            sx={{ 
              position: 'absolute', 
              bottom: 160, 
              left: 10,
              zIndex: 10, 
              textShadow: '1px 1px 2px black, 0 0 5px black, 0 0 10px black',
              textAlign: 'left',
              fontSize: 11,
              color: 'white',
            }}
          >
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                border: (theme) => `1px solid ${theme.palette.divider}`,
                backgroundColor: 'rgba(255,255,255,0.3)',
                padding: '5px',
                color: 'white',
                flexWrap: 'wrap',
              }}
            >
              {playingSpeed}x speed <br/>
              Time: {props.currentSatPos.timeStemp}<br/>
              Visualisation:<br/>
              Start time: {props.satTrack.timeStemps[props.currentSatPos.showTiemrange[0]]}<br/>
              End time: {props.satTrack.timeStemps[props.currentSatPos.showTiemrange[1]]}<br/>
              Simulation:<br/>
              Start time: {props.satTrack.timeStemps[0]}<br/>
              End time: {props.satTrack.timeStemps[props.satTrack.timeStemps.length - 1]}
            </Paper>
          </Box>}

          <Stack direction="row" spacing={1}
            sx={{ 
              position: 'absolute', 
              bottom: 15, 
              left: 55,
              zIndex: 10,
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                border: (theme) => `1px solid ${theme.palette.divider}`,
                backgroundColor: 'rgba(255,255,255,0.4)',
                flexWrap: 'wrap',
              }}
            >
              <ToggleButtonGroup
                value=""
                exclusive
                onChange={(e, val) => handlePlayingSpeedClick(val)}
                aria-label="text alignment"
                size='small'
              >
                <ToggleButton value={-1} aria-label="rewind">
                  <FastRewindIcon />
                </ToggleButton>
                <ToggleButton value={1} aria-label="forward">
                  <FastForwardIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Paper>
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                border: (theme) => `1px solid ${theme.palette.divider}`,
                backgroundColor: 'rgba(255,255,255,0.4)',
                flexWrap: 'wrap',
              }}
            >
              <ToggleButton 
                size='small'
                value="play"
                selected={playing}
                aria-label="right aligned"
                onClick={handlePlayingClick}
              >
                {playing ? <PauseIcon /> : <PlayArrowIcon />}
              </ToggleButton>
            </Paper>
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                border: (theme) => `1px solid ${theme.palette.divider}`,
                backgroundColor: 'rgba(255,255,255,0.4)',
                flexWrap: 'wrap',
              }}
            >
              <ToggleButton 
                size='small'
                value="showtext"
                selected={showText}
                aria-label="right aligned"
                onClick={() => setShowText(!showText)}
              >
                {showText ? <AssignmentIcon /> : <AssignmentOutlinedIcon />}
              </ToggleButton>
            </Paper>
          </Stack>
    </div>
  );
}

const mapStateToProps = state => {
    return { 
      satTrack: state.scenarios[state.currentScenarioKey].analytics.predictions.satPos, 
      currentSatPos: state.satellite.satPos 
    };
};

export default connect(mapStateToProps, { setSatPosition, setShowTimerange })(TimeSlider);