import React from "react";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
// import "./AboutSMEP.css";


export default function AboutSMEP() {
// const preventDefault = (event) => event.preventDefault();

  return (
    <div className="AboutSMEP">
      <Container maxWidth="xl" sx={{paddingTop: 10, paddingBottom: 10}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{
              px:2, py:2,
              textAlign: 'center',
              color: 'secondary',
            }}>
              <h1>About us</h1>
              <p>
                Our solution provides a fast-track for mission validation without the use of complicated and expensive software. This analysis tool is an easy-to-use and easy-to-learn cloud-based application where you can start to build your satellite mission within minutes.<br />
              </p>
              <Button variant="contained" color="primary" href="https://ephemersys.com" target="_blank">
                Check out our website
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{
              px:2, py:2,
              textAlign: 'center',
              color: 'secondary',
            }}>
              <h1>How to use our software?</h1>
              <p>
                You can find a constantly updated documentation for our software here.<br />
              </p>
              <Button variant="contained" color="primary" href="https://ephemersys.atlassian.net/servicedesk/customer/portal/3" target="_blank">
                Check out our knowledgebase
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{
              px:2, py:2,
              textAlign: 'center',
              color: 'secondary',
            }}>
              <h1>Any questions?</h1>
              <p>
                In case of any question, you can contact us through our website or submit a support ticket in our Service Desk. We usually reply within one working day.<br />
              </p>
              <Button variant="contained" color="primary" href="https://ephemersys.atlassian.net/servicedesk/customer/portal/3" target="_blank">
              Get support or report a problem
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <p>All rights reserved, Ephemersys LLC., 2020-2022</p>
          </Grid>
          <Grid item xs={6}>
            <p>
              <Link href="https://www.ephemersys.com/dataprotection" target="_blank">
                Data protection and Privacy Policy
              </Link>
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}