import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import DesignMenuButton from '../menus/DesignMenuButton';
import AnalyticsMenuButton from '../menus/AnalyticsMenuButton';
import { connect } from 'react-redux';
import { setCurrentScenarioKey, resetDoneAnalytics } from '../../redux/actions';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteScenarioById } from '../utils/scenarioQery';


function ScenarioCard(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setDeleting(true)
    deleteScenarioById(props.scenario.id)
    .then(() => {
      setDeleting(false)
      props.getScenarios()
    })
    .catch(err => console.log(err))
    setOpen(false);
  }

  const handleCardClicl = () => {
    props.setCurrentScenarioKey(props.scenario.id); 
    props.resetDoneAnalytics(props.scenario.id);
  }

  return (
    deleting || 
    <>
      <Card 
        onClick={handleCardClicl}
        sx={{ minWidth: 230 }}
      >
        <CardMedia
          component="img"
          height="140"
          image="/logo280.png"
          alt="green iguana"
        />
        <CardContent sx={{ height: 110 }}>
          <Typography gutterBottom variant="h5" component="div">
            {props.scenario.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.scenario.description}
          </Typography>
        </CardContent>
        <CardActions>
          <IconButton 
            aria-label="dashboard"
            color="primary"
            id='dashboard'
            onClick={() => navigate('/scenario/dashboard')}
          >
            <DashboardIcon />
          </IconButton>
          <DesignMenuButton />
          <AnalyticsMenuButton />
          <IconButton 
            disabled={props.disableDelete}
            aria-label="delete"
            color="primary"
            id='delete'
            onClick={handleClickOpen}
          >
            <DeleteForeverIcon />
          </IconButton>
        </CardActions>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete {props.scenario.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By deleting {props.scenario.name} will erase all of its data and it couldn't be restored later. 
            Are You Sure Want to Delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default connect( null, { setCurrentScenarioKey, resetDoneAnalytics })(ScenarioCard);