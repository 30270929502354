import React, { 
  Suspense,
  useState
} from "react";
import { Button, Container, Grid, Link, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Box from '@mui/material/Box';
import SliderDataSetter from './ui-components/SliderDataSetter';
import julian from 'astronomia/julian';
const Plot = React.lazy(() => import('react-plotly.js'));


export default function RadiationAnalysis() {
  const [isLoading, setLoading] = useState(false);
  const [jsonFiles, setJsonFiles] = useState({});
  const [shielding, setShielding] = React.useState(() => [1]);
  // const [duration, setDuration] = useState(5);
  // const [dataType, setDataType] = React.useState(() => ['Ap8', 'Ap9']);
  const [shieldingList, setShieldingList] = useState([0.5, 1, 2])

  const [altitude, setAltitude] = useState(1);
  const [inclination, setInclination] = useState(45);
  const [material, setMaterial] = React.useState('Aluminium');
  
  const altitudeMarks = [
    {value: 0, label: '450 km',},
    {value: 1, label: '500 km',},
    {value: 2, label: '2000 km',},
    {value: 3, label: '36000 km',},
  ];
  
  const incinationMarks = [
    {value: 0, label: '0°',},
    {value: 5, label: '5°',},
    {value: 45, label: '45°',},
    {value: 51.6, label: '51.6°',},
    {value: 90, label: '90°',},
  ];

  // const durationMarks = [
  //   {value: 1, label: '1 yr',},
  //   {value: 5, label: '5 yrs',},
  //   {value: 10, label: '10 yrs',},
  //   {value: 15, label: '15 yrs',},
  //   {value: 25, label: '25 yrs',},
  // ];

  // const isApAeDisabled = (thisA) => {
  //   if (!dataType.includes(thisA) && dataType.length === 2) {return true}
  //   if (dataType.includes('Ae8') && thisA === 'Ap9') {return true}
  //   if (dataType.includes('Ap9') && thisA === 'Ae8') {return true}
  //   if (dataType.includes('Ae9') && thisA === 'Ap8') {return true}
  //   if (dataType.includes('Ap8') && thisA === 'Ae9') {return true}
  //   return false
  // }

  const handleMaterialChange = (event, newMaterial) => {
    if (newMaterial !== null){
      setMaterial(newMaterial);
    }
  };

  const handleShieldingChange = (event, newShielding) => {
    if (newShielding !== null){
      setShielding(newShielding);
    }
  };

  // const handleDatatypeChange = (event, newDataType) => {
  //   setDataType(newDataType);
  // };

  // const typeInfo = () => {
  //   if (dataType.includes('Ae8') && dataType.includes('Ap8')) {return 'Plot: Ae8 + Ap8'}
  //   if (dataType.includes('Ae9') && dataType.includes('Ap9')) {return 'Plot: Ae9 + Ap9'}
  //   return ''
  // }

  const handleRun = () => {
    const ae_path_string = 'AE9_'+inclination+'_'+altitudeMarks.filter(i => i.value === altitude)[0].label.split(' ')[0]+'_'+material
    const ap_path_string = 'AP9_'+inclination+'_'+altitudeMarks.filter(i => i.value === altitude)[0].label.split(' ')[0]+'_'+material
    console.log(ae_path_string, ap_path_string);
    const radDataFiles = [
      //'https://ephemersys-mvp-media-dev.s3.eu-west-1.amazonaws.com/radiation/Ae9/' + path_string + '/Teszt_Ae9ShortOutput_mean_doseaccum.json',
      //'https://ephemersys-mvp-media-dev.s3.eu-west-1.amazonaws.com/radiation/Ae9/' + path_string + '/Teszt_Ae9ShortOutput_mean_doserate.json',
      //'https://ephemersys-mvp-media-dev.s3.eu-west-1.amazonaws.com/radiation/Ae9/' + path_string + '/Teszt_Ae9ShortOutput_mean_flux.json',

      'https://amplify-mvp-dev-storage200824-dev.s3.eu-west-1.amazonaws.com/public/radiation/Ae9/' + ae_path_string + '/Teszt_Ae9ShortOutput_mean_doseaccum.json',
      'https://amplify-mvp-dev-storage200824-dev.s3.eu-west-1.amazonaws.com/public/radiation/Ae9/' + ae_path_string + '/Teszt_Ae9ShortOutput_mean_doserate.json',
      'https://amplify-mvp-dev-storage200824-dev.s3.eu-west-1.amazonaws.com/public/radiation/Ae9/' + ae_path_string + '/Teszt_Ae9ShortOutput_mean_flux.json',

      'https://amplify-mvp-dev-storage200824-dev.s3.eu-west-1.amazonaws.com/public/radiation/Ap9/' + ap_path_string + '/Ap9ShortOutput_mean_doseaccum.json',
      'https://amplify-mvp-dev-storage200824-dev.s3.eu-west-1.amazonaws.com/public/radiation/Ap9/' + ap_path_string + '/Ap9ShortOutput_mean_doserate.json',
      'https://amplify-mvp-dev-storage200824-dev.s3.eu-west-1.amazonaws.com/public/radiation/Ap9/' + ap_path_string + '/Ap9ShortOutput_mean_flux.json',
    ]

    setLoading(true)
    const handleAddJsonFile = (addr, json3) => {
      setJsonFiles(old => ({...old, [addr]: json3}));
      if (addr.includes('dose')) {
        const list = json3.data.map(v => v.variableValue)
        setShieldingList(list)
        setShielding(list)
      }
    }
    //'https://amplify-mvp-dev-storage200824-dev.s3.eu-west-1.amazonaws.com/public/radiation/Ae9/AE9_45_500_Aluminium/Teszt_Ae9ShortOutput_mean_doseaccum.json'
    //'https://amplify-mvp-dev-storage200824-dev.s3.eu-west-1.amazonaws.com/public/radiation/Ae9/AE9_45_500_Aluminium/Teszt_Ae9ShortOutput_mean_doseaccum.json'

    // fetch('https://ephemersys-mvp-media-dev.s3.eu-west-1.amazonaws.com/radiation/rad_filelist.json')
    //   .then((response) => response.json())
    //   .then((json) => {
    //     json['files'].forEach(address => {
    //       fetch(address)
    //       .then(res => res.json())
    //       .then(json2 => handleAddJsonFile(address, json2))
    //       .catch((error) => console.error(error))
    //     })
    //   })
    //   .catch((error) => console.error(error))
    //   .finally(() => setLoading(false));

    radDataFiles.forEach(address => {
      console.log(address);
      fetch(address)
      .then(res => res.json())
      .then(json2 => handleAddJsonFile(address, json2))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
    })
    // setLoading(false)
  }

  return (
    <Container maxWidth="xl">
      <Box className="RadiationAnalysis" sx={{paddingTop: 10, paddingBottom: 10}}>
        <h3>Radiation Analysis Page</h3>
        <Grid container spacing={3} alignItems="center">
          <Grid item sx={{ minWidth: '100%' }}>
            
            <SliderDataSetter 
              name="Altitude [km]"
              icon=""
              inputProps={{
                track: false,
                step: null,
                min: -.25,
                max: 3.25,
                valueLabelDisplay: "off",
                marks: altitudeMarks,
              }}
              value={altitude}
              setValue={setAltitude}
            />

            <SliderDataSetter 
              name="Inclination [deg]"
              // icon={<VolumeUp />}
              inputProps={{
                min: -5,
                max: 95,
                step: null,
                marks: incinationMarks,
                track: false,
                valueLabelDisplay: "off",
              }}
              value={inclination}
              setValue={setInclination}
            />

            <ToggleButtonGroup
              color="primary"
              value={material}
              exclusive
              onChange={handleMaterialChange}
              aria-label="Platform"
            >
              <ToggleButton value="Aluminium">Aluminium</ToggleButton>
              <ToggleButton value="Silicon">Silicon</ToggleButton>
            </ToggleButtonGroup>

            <br/> <br/>
            
            <ToggleButtonGroup
              color="primary"
              value={shielding}
              // exclusive
              onChange={handleShieldingChange}
              aria-label="Platform"
            >
              {shieldingList.map((v, i) => {
                return <ToggleButton key={i} value={v}>{v} mm</ToggleButton>
              })}
            </ToggleButtonGroup>
            
            <br/> <br/>
{/* 
            <SliderDataSetter 
              name="Time in Orbit [years]"
              // icon={<VolumeUp />}
              inputProps={{
                min: 1,
                max: 25,
                step: 1,
                marks: durationMarks,
              }}
              value={duration}
              setValue={setDuration}
            />

            <ToggleButtonGroup
              color="primary"
              value={dataType}
              onChange={handleDatatypeChange}
              aria-label="Platform"
            >
              <ToggleButton disabled={isApAeDisabled('Ap8')} value='Ap8'>Ap8</ToggleButton>
              <ToggleButton disabled={isApAeDisabled('Ap9')} value='Ap9'>Ap9</ToggleButton>
              <ToggleButton disabled={isApAeDisabled('Ae8')} value='Ae8'>Ae8</ToggleButton>
              <ToggleButton disabled={isApAeDisabled('Ae9')} value='Ae9'>Ae9</ToggleButton>
            </ToggleButtonGroup> */}


            <br />
            {/* {typeInfo()} */}

            {
              isLoading 
              ? <Button disabled variant='contained'>Loading ...</Button>
              : <Button onClick={handleRun} variant='contained'>Fetch Data</Button>
            }

          </Grid>
          <Grid item>
          The electron and proton flux according to the AE9 and AP9 models for 120 configurations (4 altitudes, 2 shielding materials, 3 shielding thicknesses and 5 orbit inclinations) are plotted for a 2 / 12 months orbit time. The dose rate and the the total dose deposited under a shielding layer with given thickness and material are also shown. The data was obtained by the <Link target="_blank" rel="noreferrer" href="https://www.vdl.afrl.af.mil/programs/ae9ap9/">IRENE</Link> tool. For calculation of the dose deposition and dose rate a sphere with the given shielding was assumed.
          </Grid>
          <Grid item>
            <Box sx={{ width: '100%', height: '100%'}}>
            <Suspense fallback={<div>Loading...</div>}>
              {!isLoading &&
              // [doseaccumData, doserateData, fluxData]
                Object.values(jsonFiles).map((ii, index) =>  { return( 
                <Plot
                  key={index}
                  data={ii.data.filter(di => di.variableType === "shielding_depths" ? shielding.includes(di.variableValue) : true).map(item => {
                    let timestemps = ii.datetime.map(val => julian.JDToDate(julian.MJDToJD(val)))
                    return {
                      x: timestemps,
                      y: item.values,
                      type: 'scatter',
                      mode: 'lines',
                      // marker: {color: 'red'},
                      name: item.variableName + ' ' + item.variableValue.toString() + ' ' + item.variableDimension,
                    }})
                  }
                  layout={{
                    width: 750, 
                    height: 400, 
                    title: Object.keys(jsonFiles)[index].includes('Ae9') ? 'Electron '+ii.name+' (Model: AE9)': 'Proton '+ii.name+' (Model: AP9)',
                    yaxis: {title: ii.dimension.includes('cm') ? '[cm<sup>-2</sup> s<sup>-1</sup>]' : '['+ii.dimension+']' },
                    legend: {orientation: "h", x: 0, y: -0.15}
                  }}
                />
                )})}
            </Suspense>
            </Box>
            
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
