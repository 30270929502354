import React, { useEffect, useState } from "react";
import KeplerianForm from './KeplerianForm';
import TleForm from './TleForm';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { connect } from "react-redux";
import { setOrbitType } from "../redux/actions";
import { getOrbitOfSat } from "./utils/scenarioQery";


function MissionDesign(props) {
  const [view, setView] = React.useState('KEPLER');
  const [isDemo, setDemo] = useState(false);
  const [orbitData, setOrbitData] = useState(undefined);

  useEffect(() => {
    if (props.scenarioKey !== 'new-scenario') {
      getOrbitOfSat(props.scenarioKey)
      .then(({demo, orbit}) => {
        setDemo(demo);
        setView(orbit.type);
        setOrbitData(orbit);
      })
    }
    console.log('hello caller! 1');
  }, [props.scenarioKey])


  const handleChange = (event) => {
    setView(event.target.value);
    if (props.scenarioKey !== 'new-scenario') {
      getOrbitOfSat(props.scenarioKey)
      .then(({demo, orbit}) => {
        setDemo(demo);
        setOrbitData(orbit);
        
      })
    }

    console.log('changed ++');
    

    if (props.scenarioKey === 'new-scenario') {
      props.setOrbitType(props.scenarioKey, event.target.value);
    }
  };

  function renderKeplerian() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          Keplerian Elements
          <KeplerianForm orbit={orbitData} isDemo={isDemo} />
        </Grid>
      </Grid>
    )
  };
  function renderTLE() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <TleForm orbit={orbitData} isDemo={isDemo} />
        </Grid>
      </Grid>
    )
  };
  function renderSSO() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          SSO Orbit
        </Grid>
      </Grid>
    )
  };
  let renderView;
  if (view === 'KEPLER') {
    renderView = renderKeplerian();
  } else if (view === 'TLE') {
    renderView = renderTLE();
  } else if (view === 'SSO') {
    renderView = renderSSO();
  }

  return (
    <Container maxWidth='xl' sx={{paddingTop: 10, paddingBottom: 10}}>
      <h3>Mission Design Page</h3>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={1}
        m={1}
      >
        <FormControl 
          variant="outlined" 
          size='small' 
          sx={{
            margin: 1,
            minWidth: 160,
          }}
        >
          <InputLabel id="window-select-outlined-label">Select Form</InputLabel>
          <Select
            // native
            disabled={isDemo}
            labelId="window-select-outlined-label"
            label="Select Form"
            id="window-select-outlined"
            value={view}
            onChange={handleChange}
          >
            <MenuItem value={'KEPLER'}>Keplerian</MenuItem>
            <MenuItem value={'TLE'}>TLE</MenuItem>
            <MenuItem value={'SSO'} disabled>SSO</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {renderView}
    </Container>
  );
}

const mapStateToProps = state => {
  return { 
    // orbit: state.scenarios[state.currentScenarioKey].definitions.orbit,
    scenarioKey: state.currentScenarioKey,
    // times: {
    //   startTime: state.scenarios[state.currentScenarioKey].startTime,
    //   endTime: state.scenarios[state.currentScenarioKey].endTime,
    //   timeResolution: state.scenarios[state.currentScenarioKey].timeResolution,
    // }
  }
};

export default connect(mapStateToProps, { setOrbitType })(MissionDesign);
