import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import Typography from '@mui/material/Typography';
import AnalyticsMenuList from './AnalyticsMenuList';
import DesignMenuList from './DesignMenuList';
import { connect } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';


function ScenarioMenuButton(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box>
        <Tooltip title="Scenario Menu">
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="outlined"
                startIcon={<GroupWorkIcon />}
            >
                Scenario
            </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="basic-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem
            disabled={location.pathname === '/scenario/dashboard'}
            onClick={() => navigate('/scenario/dashboard')}
        >
            <Typography>{'Dashboard'}</Typography>
        </MenuItem>
        <Divider />
        <DesignMenuList />
        <Divider />
        <AnalyticsMenuList />
      </Menu>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return { scenarioKey: state.currentScenarioKey, scenario: state.scenarios[state.currentScenarioKey] }
}

export default connect(mapStateToProps,)(ScenarioMenuButton);