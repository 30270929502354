export function tleParser(tle) {
    let tleLines = tle.split('\n');
    let line1 = tleLines[0];
    let line2 = tleLines[1] ? tleLines[1] : '';
    const fields = {
      line1: [line1.slice(0, 1),
      line1.slice(2, 7),
      line1.slice(7, 8),
      line1.slice(9, 11),
      line1.slice(11, 14),
      line1.slice(14, 17),
      line1.slice(18, 20),
      line1.slice(20, 32),
      line1.slice(33, 43),
      line1.slice(44, 52),
      line1.slice(53, 61),
      line1.slice(62, 63),
      line1.slice(64, 68),
      line1.slice(68, 69),],
      line2: [line2.slice(0, 1),
      line2.slice(2, 7),
      line2.slice(8, 16),
      line2.slice(17, 25),
      line2.slice(26, 33),
      line2.slice(34, 42),
      line2.slice(43, 51),
      line2.slice(52, 63),
      line2.slice(63, 68),
      line2.slice(68, 69),],
      line1labels: ['Line number',
        'Satellite catalog number',
        'Classification (U=Unclassified, C=Classified, S=Secret)',
        'International Designator (last two digits of launch year)',
        'International Designator (launch number of the year)',
        'International Designator (piece of the launch)',
        'Epoch Year (last two digits of year)',
        'Epoch (day of the year and fractional portion of the day)',
        'First Derivative of Mean Motion aka the Ballistic Coefficient',
        'Second Derivative of Mean Motion (decimal point assumed)',
        'Drag Term aka Radiation Pressure Coefficient or BSTAR (decimal point assumed)',
        'Ephemeris type (internal use only - always zero in distributed TLE data)',
        'Element set number. Incremented when a new TLE is generated for this object.',
        'Checksum (modulo 10)',],
      line2labels: ['Line number',
        'Satellite Catalog number',
        'Inclination (degrees)',
        'Right Ascension of the Ascending Node (degrees)',
        'Eccentricity (decimal point assumed)',
        'Argument of Perigee (degrees)',
        'Mean Anomaly (degrees)',
        'Mean Motion (revolutions per day)',
        'Revolution number at epoch (revolutions)',
        'Checksum (modulo 10)',],
    };
    const rows1 = fields.line1.map((field, index) => ({ index: index + 1, field, name: fields.line1labels[index] }));
    const rows2 = fields.line2.map((field, index) => ({ index: index + 1, field, name: fields.line2labels[index] }));
    return ({ rows1, rows2 });
  }

export function calculateTleEpochDateTime(tle) {
    let tleTables = tleParser(tle)
    let epochyr = parseInt(tleTables.rows1[6].field);
    let epochdays = parseFloat(tleTables.rows1[7].field);

    let epochDate = new Date(Date.UTC( 
        2000+epochyr, 
        0, 
        Math.floor(epochdays), 
        0, 
        0, 
        24*60*60*(epochdays-Math.floor(epochdays)))
    );
    return epochDate;
}