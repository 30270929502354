import * as React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LinearProgress from '@mui/material/LinearProgress';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CalculateButton(props) {
    const [calculating, setCalculating] = React.useState(false);
    const [openErr, setOpenErr] = React.useState(false);
    const [alert, setAlert] = React.useState({error: false, msg: 'Done!.'});
    // const [success, setSuccess] = React.useState(false)
    const timer = React.useRef();

    React.useEffect(() => {
        let timer = undefined;
        if (openErr) {
            timer = setTimeout( () => {
                setOpenErr(false);
            }, 5000);
        }
        return () => {
          if (timer){
            clearTimeout(timer);
          }
        }
    },[openErr]);
  
    React.useEffect(() => {
          return () => {
            clearTimeout(timer.current);
          };
    }, []);

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenErr(false);
    };

    const handleButtonClick = () => {
        if (!calculating) {
          setCalculating(true);
          timer.current = window.setTimeout( () => {
            props.fn(props.args)
            // TODO: add proper error and exception handling.
            setAlert({error: false, msg: 'Done!.'})
            setCalculating(false);
            // setSuccess(true);
            setOpenErr(true);
          }, 1000)
        }
    }

    return (
        <>
        <Button 
            variant="contained"
            color={props.success ? "success" : "primary"}
            disabled={calculating}
            onClick={handleButtonClick}
            endIcon={calculating ? <CircularProgress size={15}  color="primary" /> : (props.success ? <TaskAltIcon/> : <SendIcon />)}
        >
            {props.success ? "Re-Calculate" : "Calculate"}
        </Button>
        {calculating && <LinearProgress />}
        <Snackbar open={openErr} autoHideDuration={6000} onClose={handleAlertClose}>
            <Alert onClose={handleAlertClose} severity={alert.error ? "error" : "success"} sx={{ width: '100%' }}>
                {alert.msg}
            </Alert>
        </Snackbar>
        </>
    );
}
