import { DataStore } from '@aws-amplify/datastore';
import { DemoScenario, Scenario, Satellite, Orbit, Attitude, DemoSatellite } from '../../models';

export async function listAllDemoScenario() {
    try {
        const scenarios = await DataStore.query(DemoScenario);
        return scenarios
    } catch (error) {
        console.log(error);
        return error
    }
}

export async function listAllScenario() {
    try {
        const scenarios = await DataStore.query(Scenario);
        return scenarios
    } catch (error) {
        console.log(error);
        return error
    }
}

export async function saveNewScenario(scenarioParams, satelliteParams, orbitParams, attitudeParams) {
    try {
        const scenario = await DataStore.save(new Scenario(scenarioParams));
        const orbit = await DataStore.save(new Orbit(orbitParams));
        const attitude = await DataStore.save(new Attitude(attitudeParams));
        await DataStore.save(
          new Satellite({
            ...satelliteParams,
            scenarioID: scenario.id,
            Orbit: orbit,
            Attitude: attitude
          })
        );
    } catch (error) {
        console.log(error);
    }
}

export async function getScenarioById(scenarioId) {
    try {
        let scenario = await DataStore.query(Scenario, scenarioId);
        let satellite = undefined
        if (!scenario) {
            scenario = await DataStore.query(DemoScenario, scenarioId);
            satellite = (await DataStore.query(DemoSatellite)).filter(c => c.demoscenarioID === scenario.id);
            return({
                scenario: scenario, 
                satellite: satellite[0], 
                orbit: satellite[0].DemoOrbit, 
                attitude: satellite[0].DemoAttitude
            })
        } else {
            satellite = (await DataStore.query(Satellite)).filter(c => c.scenarioID === scenario.id);
            return({
                scenario: scenario, 
                satellite: satellite[0], 
                orbit: satellite[0].Orbit, 
                attitude: satellite[0].Attitude
            })
        }   
    } catch (error) {
        console.log(error);
    }
}

export async function deleteScenarioById(scenarioId) {
    try {
        const scenarioToDelete = await DataStore.query(Scenario, scenarioId);
        const satelliteToDelete = (await DataStore.query(Satellite)).filter(c => c.scenarioID === scenarioToDelete.id);
        if (satelliteToDelete[0]){
            if (satelliteToDelete[0].Attitude) {
                await DataStore.delete(satelliteToDelete[0].Attitude);
            }
            if (satelliteToDelete[0].Orbit) {
                await DataStore.delete(satelliteToDelete[0].Orbit);
            }
            await DataStore.delete(satelliteToDelete[0]);
        }
        await DataStore.delete(scenarioToDelete);
      } catch (err) {
        console.log(err);
      }
}

export async function listAllDemoSatellite() {
    try {
        const satellites = await DataStore.query(DemoSatellite);
        return satellites;
    } catch (error) {
        console.log(error);
    }
}

export async function getSatelliteByScenarioId(scenarioId){
    try {
        let satellite = (await DataStore.query(Satellite)).filter(c => c.scenarioID === scenarioId);
        let demo = false;
        if (satellite.length === 0) {
            satellite = (await DataStore.query(DemoSatellite)).filter(c => c.demoscenarioID === scenarioId);
            demo = true;
        }
        return({demo: demo, satellite: satellite[0]})        
    } catch (error) {
        console.log(error);
    }
}

export async function updateSatOfScenario(newSatelliteKey, scenarioId) {
    try {
        const originalSat = (await DataStore.query(Satellite)).filter(c => c.scenarioID === scenarioId);
        const newSatellite = (await DataStore.query(DemoSatellite)).filter(c => c.satKey === newSatelliteKey);
        await DataStore.save(
            Satellite.copyOf(originalSat[0], updated => {
                updated.areaFile = newSatellite[0].areaFile
                updated.mediaType = newSatellite[0].mediaType
                updated.mediaUrl = newSatellite[0].mediaUrl
                updated.name = newSatellite[0].name
                updated.satKey = newSatellite[0].satKey
                updated.size = newSatellite[0].size
                updated.solarArea = newSatellite[0].solarArea
                updated.solarCellNr = newSatellite[0].solarCellNr
                updated.wings = newSatellite[0].wings
            })
        )
    } catch (error) {
        console.log(error);
    }
}

export async function getOrbitOfSat(scenarioId) {
    try {
        let scenario = await DataStore.query(Scenario, scenarioId);
        let satellite = undefined
        if (!scenario) {
            scenario = await DataStore.query(DemoScenario, scenarioId);
            satellite = (await DataStore.query(DemoSatellite)).filter(c => c.demoscenarioID === scenario.id);
            return({demo: true, orbit: satellite[0].DemoOrbit})
        } else {
            satellite = (await DataStore.query(Satellite)).filter(c => c.scenarioID === scenario.id);
            return({demo: false, orbit: satellite[0].Orbit})
        }   
    } catch (error) {
        console.log(error);
    }
}

export async function updateOrbitOfSat(scenarioId, newOrbitData) {
    try {
        const scenario = await DataStore.query(Scenario, scenarioId);
        const satellite = (await DataStore.query(Satellite)).filter(c => c.scenarioID === scenario.id);
        const originalOrbit = satellite[0].Orbit
        await DataStore.save(
            Orbit.copyOf(originalOrbit, updated => {
                if (newOrbitData.type === 'TLE'){
                    updated.tleLine1 = newOrbitData.tleLine1
                    updated.tleLine2 = newOrbitData.tleLine2
                    updated.type = newOrbitData.type
                }
                if (newOrbitData.type === 'KEPLER') {
                    updated.semiMajorAxis = newOrbitData.semiMajorAxis
                    updated.eccentricity = newOrbitData.eccentricity
                    updated.inclination = newOrbitData.inclination
                    updated.raan = newOrbitData.raan
                    updated.argOfPeriapsis = newOrbitData.argOfPeriapsis
                    updated.trueAnomaly = newOrbitData.trueAnomaly
                    updated.type = newOrbitData.type
                }
            })
        )
    } catch (error) {
        console.log(error);
    }
}

export async function getAttitudeOfSat(scenarioId) {
    try {
        let scenario = await DataStore.query(Scenario, scenarioId);
        let satellite = undefined
        if (!scenario) {
            scenario = await DataStore.query(DemoScenario, scenarioId);
            satellite = (await DataStore.query(DemoSatellite)).filter(c => c.demoscenarioID === scenario.id);
            return({demo: true, attitude: satellite[0].DemoAttitude})
        } else {
            satellite = (await DataStore.query(Satellite)).filter(c => c.scenarioID === scenario.id);
            return({demo: false, attitude: satellite[0].Attitude})
        }   
    } catch (error) {
        console.log(error);
    }
}

export async function updateAttitudeOfSat(scenarioId, newAttitudeData) {
    try {
        const scenario = await DataStore.query(Scenario, scenarioId);
        const satellite = (await DataStore.query(Satellite)).filter(c => c.scenarioID === scenario.id);
        const originalAttitude = satellite[0].Attitude
        await DataStore.save(
            Orbit.copyOf(originalAttitude, updated => {
                    updated.alignmentSatVector = newAttitudeData.alignmentSatVector
                    updated.alignmentVector = newAttitudeData.alignmentVector
                    updated.constraintSatVector = newAttitudeData.constraintSatVector
                    updated.constraintVector = newAttitudeData.constraintVector
                    updated.rotation = newAttitudeData.rotation
                    updated.rotationAxisX = newAttitudeData.rotationAxisX
                    updated.rotationAxisY = newAttitudeData.rotationAxisY
                    updated.rotationAxisZ = newAttitudeData.rotationAxisZ
                    updated.rotationSpeed = newAttitudeData.rotationSpeed
            })
        )
    } catch (error) {
        console.log(error);
    }
}

export function delScenario() {
    return 0
}

export function delSatOfScenario() {
    return 0
}

export function delOrbitOfSat() {
    return 0
}

export function delAttitudeOfSat() {
    return 0
}

export function updateScenario() {
    return 0
}
