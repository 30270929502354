import {
  twoline2satrec,
  sgp4,
  propagate,
  gstime,
  eciToGeodetic,
  degreesLong,
  degreesLat,
} from "satellite.js";
import { jday } from "satellite.js/lib/ext";
import sgp4init from "satellite.js/lib/propagation/sgp4init";
import { getTimeList } from "./solarSystem";

import { SGP4_ERRORS } from "./errorCodes";

export function propagateOneStep(tle) {
  // Sample TLE
  var tleLine1 = tle.line1;
  var tleLine2 = tle.line2;

  var satrec = twoline2satrec(tleLine1, tleLine2);

  //  Propagate satellite using time since epoch (in minutes).
  var timeSinceTleEpochMinutes = 1;
  const positionAndVelocity = sgp4(satrec, timeSinceTleEpochMinutes);
  var positionEci = positionAndVelocity.position;

  // You will need GMST for some of the coordinate transforms.
  // http://en.wikipedia.org/wiki/Sidereal_time#Definition
  var gmst = gstime(new Date());

  var positionGd = eciToGeodetic(positionEci, gmst);

  // Geodetic coords are accessed via `longitude`, `latitude`, `height`.
  var longitude = positionGd.longitude;
  var latitude = positionGd.latitude;
  var height = positionGd.height;

  //  Convert the RADIANS to DEGREES.
  var longitudeDeg = degreesLong(longitude);
  var latitudeDeg = degreesLat(latitude);

  return { gd: [longitudeDeg, latitudeDeg, height], eci: positionEci };
}

function iterate(timeList, satrec) {
  var groundTrack = [];
  var lastLongitudeDeg = null;
  var groundTrackList = [];
  let orientationList = [];

  let positionList = timeList.map((timeStamp) => {
    const positionAndVelocity = propagate(satrec, new Date(timeStamp));

    var gmst = gstime(new Date(timeStamp));
    var positionGd = eciToGeodetic(positionAndVelocity.position, gmst);

    var longitudeDeg = degreesLong(positionGd.longitude);
    var latitudeDeg = degreesLat(positionGd.latitude);
    orientationList.push([1, 1, 1]);

    if (Math.abs(longitudeDeg - lastLongitudeDeg) > 180) {
      groundTrackList.push(groundTrack);
      groundTrack = [];
    }
    groundTrack.push([latitudeDeg, longitudeDeg]);
    lastLongitudeDeg = longitudeDeg;
    return [
      positionAndVelocity.position.x,
      positionAndVelocity.position.y,
      positionAndVelocity.position.z,
    ];
  });
  groundTrackList.push(groundTrack);
  return [positionList, groundTrackList, orientationList];
}

export function propagateTle(tle) {
  let timeList = getTimeList(tle.startTime, tle.endTime, tle.timeResolution);
  if (timeList.length > 6000) {
    return { error: 99, payload: { error_message: SGP4_ERRORS[99] } };
  }

  var tleLine1 = tle.line1;
  var tleLine2 = tle.line2;

  var satrec = twoline2satrec(tleLine1, tleLine2);
  if (satrec.error > 0) {
    return {
      error: satrec.error,
      payload: { error_message: SGP4_ERRORS[satrec.error] },
    };
  }

  let [positionList, groundTrackList] = iterate(timeList, satrec);
  return {
    error: satrec.error,
    payload: {
      timeStemps: timeList,
      positionsEci: positionList,
      groundTrack: groundTrackList,
    },
  };
}

export function propageteKepler(kepler) {
  console.log(kepler);
  let timeList = getTimeList(
    kepler.startTime,
    kepler.endTime,
    kepler.timeResolution
  );

  if (timeList.length > 60000) {
    return { error: 99, payload: { error_message: SGP4_ERRORS[99] } };
  }

  let semimajoraxis = kepler.semiMajorAxis;
  const opsmode = "i";
  const mu = 398600.5; // in km3 / s2
  const xpdotp = 1440.0 / (2.0 * Math.PI); // 229.1831180523293;
  const earthRadius = 6378.137; // in km
  const xke = 60.0 / Math.sqrt((earthRadius * earthRadius * earthRadius) / mu);
  const tumin = 1.0 / xke;
  const deg2rad = Math.PI / 180.0;

  const satrec = {
    error: 0,
    satnum: "1",
    epochyr: 8,
    epochdays: 1.1,
    ndot: 0.0,
    nddot: 0.0,
    bstar: 0.0,
    inclo: kepler.inclination,
    ecco: kepler.eccentricity,
    argpo: kepler.argOfPeriapsis,
    mo: kepler.trueAnomaly * 1.0,
    no: 15.72,
    nodeo: kepler.raan,
  };
  // ---- find no, ndot, nddot ----
  satrec.no =
    (86400.0 * Math.sqrt(mu)) / (2 * Math.PI * semimajoraxis ** (3 / 2));
  satrec.no /= xpdotp; //   rad/min

  // ---- convert to sgp4 units ----
  satrec.a = (satrec.no * tumin) ** (-2.0 / 3.0);

  satrec.ndot /= xpdotp * 1440.0; // ? * minperday
  satrec.nddot /= xpdotp * 1440.0 * 1440;

  // ---- find standard orbital elements ----
  satrec.inclo *= deg2rad;
  satrec.nodeo *= deg2rad;
  satrec.argpo *= deg2rad;
  satrec.mo *= deg2rad;
  satrec.alta = satrec.a * (1.0 + satrec.ecco) - 1.0;
  satrec.altp = satrec.a * (1.0 - satrec.ecco) - 1.0;

  // ----------------------------------------------------------------
  // find sgp4epoch time of element set
  // remember that sgp4 uses units of days from 0 jan 1950 (sgp4epoch)
  // and minutes from the epoch (time)
  // ----------------------------------------------------------------
  let starTime = new Date(timeList[0]);
  satrec.jdsatepoch = jday(
    starTime.getUTCFullYear(),
    starTime.getUTCMonth(),
    starTime.getUTCDate(),
    starTime.getUTCHours(),
    starTime.getUTCMinutes(),
    starTime.getUTCSeconds()
  );

  //  ---------------- initialize the orbit at sgp4epoch -------------------
  sgp4init(satrec, {
    opsmode,
    satn: satrec.satnum,
    epoch: satrec.jdsatepoch - 2433281.5,
    xbstar: satrec.bstar,
    xecco: satrec.ecco,
    xargpo: satrec.argpo,
    xinclo: satrec.inclo,
    xmo: satrec.mo,
    xno: satrec.no,
    xnodeo: satrec.nodeo,
  });
  if (satrec.error > 0) {
    return {
      error: satrec.error,
      payload: { error_message: SGP4_ERRORS[satrec.error] },
    };
  }

  let [positionList, groundTrackList, orientationList] = iterate(
    timeList,
    satrec
  );
  return {
    error: satrec.error,
    payload: {
      timeStemps: timeList,
      positionsEci: positionList,
      groundTrack: groundTrackList,
      orientationEci: orientationList,
    },
  };
}
