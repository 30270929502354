import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/Home";
import SatDesign from "./containers/SatDesign";
import MissionDesign from "./containers/MissionDesign";
import NotFound from "./containers/NotFound";
import OrbitPrediction from "./containers/OrbitPrediction";
import RadiationAnalysis from "./containers/RadiationAnalysis";
import Lifetime from "./containers/Lifetime";
import AboutSMEP from "./containers/AboutSMEP";
import Settings from "./containers/Settings";
import NewScenario from "./containers/NewScenario";
import AttitudeProfile from './containers/AttitudeProfile';
import PowerAnalysis from "./containers/PowerAnalysis";
import ScenarioDashboard from "./containers/ScenarioDashboard";
import { DataStore, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';


export default function AppRoutes() {
  const [isDataStoreReady, setDataStoreReady] = useState(false);
  useEffect(() => {
    const startDS = async () => {
      // await DataStore.clear()
      await DataStore.start()
    }
    startDS()
    
    Hub.listen("datastore", async hubData => {
      const  { event, } = hubData.payload;
      if (event === "ready") {
        setDataStoreReady(true);
      }
    })
  }, [])

  return (isDataStoreReady ? 
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/scenario/dashboard" element={<ScenarioDashboard />} />
      <Route path="/scenario/sat" element={<SatDesign newScenario={false}/>} />
      <Route path="/scenario/mission" element={<MissionDesign />} />
      <Route path="/scenario/orientation" element={<AttitudeProfile />} />
      <Route path="/scenario/orbit" element={<OrbitPrediction />} />
      <Route path="/scenario/radiation" element={<RadiationAnalysis />} />
      <Route path="/scenario/lifetime" element={<Lifetime />} />
      <Route path="/scenario/power" element={<PowerAnalysis />} />
      <Route path="/aboutsmep" element={<AboutSMEP />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/new" element={<NewScenario />} />
      {/* Finally, catch all unmatched routes */}
      <Route path="*" element={<NotFound/>} />
    </Routes>
    :
    <LinearProgress />
  );
}
