import React, { Suspense } from "react";
// import Plot from 'react-plotly.js';

const Plot = React.lazy(() => import('react-plotly.js'));

export default function TimeSeriesChart({data}) {
    return (
        <div className="TimeSeriesChart">
            <Suspense fallback={<div>Loading...</div>}>
            <Plot
                data={[
                {
                    x: data.timestemps,
                    y: data.groundtrack.flatMap(item => item.map(pos => pos[0])),
                    type: 'scatter',
                    mode: 'lines+markers',
                    marker: {color: 'red'},
                },
                {
                    x: data.timestemps,
                    y: data.groundtrack.flatMap(item => item.map(pos => pos[1])),
                    type: 'scatter',
                    mode: 'lines+markers',
                    marker: {color: 'blue'},
                },
                ]}
                layout={{width: 920, height: 440, title: 'Lat Lon Chart'}}
            />
            </Suspense>

        </div>
    );
}
