import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Input from '@mui/material/Input';


export default function SliderDataSetter(props) {
    // const [value, setValue] = React.useState(props.value);
  
    const handleSliderChange = (event, newValue) => {
      props.setValue(newValue);
    };
  
    // const handleInputChange = (event) => {
    //   setValue(event.target.value === '' ? '' : Number(event.target.value));
    // };
  
    // const handleBlur = (mms) => {
    //   if (value < mms.min) {
    //     setValue(mms.min);
    //   } else if (value > mms.max) {
    //     setValue(mms.max);
    //   } else if (((value - mms.min) % mms.step) !== 0) {
    //     setValue(value - ((value - mms.min) % mms.step));
    //   }
    // };
  
    return (
      <>
        <Typography id="input-slider" sx={{ textAlign: 'left' }} gutterBottom>
          {props.name}
        </Typography>
        <Grid container spacing={6} alignItems="center">
          {props.icon && 
            <Grid item>
              {props.icon}
            </Grid>
          }
          <Grid item xs>
            <Slider
              value={typeof props.value === 'number' ? props.value : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              {...props.inputProps}
            />
          </Grid>
          <Grid item>
            <Input
              sx={{ width: '8rem', }}
              size="small"
              disabled
              value={
                !props.inputProps.step 
                ? props.inputProps.marks.filter(v => v.value === props.value)[0].label 
                : String(props.value)+' yrs'
              }
              // margin="dense"
              // onChange={handleInputChange}
              // onBlur={() => handleBlur(props.inputProps)}
              // inputProps={{
              //   ...props.inputProps,
              //   type: 'text',
              //   'aria-labelledby': 'input-slider',
              // }}
            />
          </Grid>
        </Grid>
      </>
    );
}
