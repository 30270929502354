import React from 'react';
import { connect } from "react-redux";
import { ArcRotateCamera, 
         Vector3, 
         HemisphericLight, 
         MeshBuilder, 
         Tools, 
         Color3, 
         DirectionalLight, 
         Mesh, 
         StandardMaterial, 
         Plane,
        //  GlowLayer,
         Path3D,
         Quaternion,
         Matrix,
        } from '@babylonjs/core';
import { GridMaterial } from '@babylonjs/materials';
import SceneComponent from "babylonjs-hook";
import * as bu from './util/utils';
import {
  BODY_X,
  BODY_Y,
  BODY_Z,
  SUN,
  VELOCITY_ECI,
  NADIR,
  ZENITH,
  ORBIT_NORMAL,
  ORBIT_RADIAL,
  // VELOCITY_ECEF,
} from '../utils/satAttitudes';
import './ScenarioRender.css';

// Augments the scene with the debug methods
// import "@babylonjs/core/Debug/debugLayer";

// Injects a local ES6 version of the inspector to prevent automatically relying on the none compatible version
// import "@babylonjs/inspector"; 

let iss;
let earth;
let issPosIndex = 0;
var orbitPointsToShow = [];
// var satOrientation = [];
let camera;
let grid;
const earthRadius = 6371;
let timeStamp;
let rotAngle;
let lines;
let showposrange;
let lineOptions;
let sunPosition;
let sun;

var tangents;
var normals;
var binormals;

var axis1;
var axis2;
var axis3;
var rotation;

const onSceneReady = (scene, props) => {
  scene.useRightHandedSystem = true;
  scene.clearColor = new Color3(0.0, 0.0, 0.0);
  const canvas = scene.getEngine().getRenderingCanvas();

  // Camera
  camera = new ArcRotateCamera("Camera", Tools.ToRadians(30), Tools.ToRadians(65), 5, new Vector3.Zero(), scene);
  camera.setTarget(Vector3.Zero());
  camera.upVector = new Vector3(0, 0, 1);
  camera.attachControl(canvas, true);
  camera.lowerRadiusLimit = 3;
  camera.upperRadiusLimit = 50;
  camera.wheelPrecision = 100;
  camera.getFrontPosition()

  // Lights
  var light = new DirectionalLight("sun", new Vector3(0, 0, -1), scene);
  light.diffuse = new Color3(1, 1, 1);
  light.position = new Vector3(0, 0, 20);
  light.intensity = 1;
  light.specular = new Color3(0.1, 0.1, 0.1);

  var hemo_light = new HemisphericLight("light1", new Vector3(0, 0, 0), scene);
  hemo_light.diffuse = new Color3(1, 1, 1);
  hemo_light.position = new Vector3(0, 0, 20);
  hemo_light.intensity = 0.7;
  hemo_light.specular = new Color3(0, 0, 0);

  // SKY
  bu.addSky(scene);

  // Show Axes
  // bu.showAxis(1.5, scene);
  // TODO: add stencil to the engine, delete babylonjshook!!!

  // GRID
  var sourcePlane = new Plane(0, 0, 1, 0);
  sourcePlane.normalize();
  var plane = MeshBuilder.CreatePlane("plane", { height:300, 
                                                 width: 300, 
                                                 sourcePlane: sourcePlane, 
                                                 sideOrientation: Mesh.DOUBLESIDE }, scene);
  grid = new GridMaterial("groundMaterial", scene);
  grid.majorUnitFrequency = 5;
  grid.minorUnitVisibility = 0.45;
  grid.gridRatio = .3;
  grid.backFaceCulling = false;
  grid.mainColor = new Color3(1, 1, 1);
  grid.lineColor = new Color3(1.0, 1.0, 1.0);
  grid.opacity = 0.1;
  plane.material = grid;

  // SUN
  sun = MeshBuilder.CreateSphere("sun", {diameter: 2}, scene);
  if (sunPosition[issPosIndex] !== undefined ) {
    sun.position = new Vector3(
      sunPosition[issPosIndex][0]*200, 
      sunPosition[issPosIndex][1]*200, 
      sunPosition[issPosIndex][2]*200);
  } else {
    sun.position = new Vector3(
      1*200, 
      0*200, 
      0*200);
  }

  let sunMaterial = new StandardMaterial("sunMaterial", scene);
  sunMaterial.diffuseColor = new Color3(0, 0, 0);
  sunMaterial.emissiveColor = new Color3(1.0, 0.5, 0.0);
  sun.material = sunMaterial;

  // TODO: add stencil to the engine, delete babylonjshook!!!
  // var gl = new GlowLayer("glow", scene);
  // gl.intensity = 2;
  // gl.addIncludedOnlyMesh(sun);

  // EARTH
  earth = bu.addEarth(scene);
  let earthAxes = bu.localAxes(2);
  earthAxes.parent = earth;
  // gl.addIncludedOnlyMesh(earth);

  //Create lines 
  lineOptions = { points: orbitPointsToShow, updatable: true }
  lines = MeshBuilder.CreateLines("lines", lineOptions);

// =======================================================================
// =======================================================================

  // Create Path3D from array of points
  var nadirVectors = orbitPointsToShow.map((orbitalPoint) => (new Vector3(0,0,0)).subtract(orbitalPoint));
  var zenithVectors = orbitPointsToShow;
  var sunVectors = orbitPointsToShow.map((orbitalPoint, index) => (new Vector3(
    sunPosition[index][0]*200, 
    sunPosition[index][1]*200, 
    sunPosition[index][2]*200)).subtract(orbitalPoint));

  var path3d = new Path3D(orbitPointsToShow, nadirVectors[0]);
  tangents = path3d.getTangents();  //array of tangents to the curve
  normals = path3d.getNormals(); //array of normals to the curve
  binormals = path3d.getBinormals(); //array of binormals to curve

  var velocityEciVectors = tangents;
  var orbitNormalVectors = binormals;
  var orbitRadials = normals.map((normal, index) => rotateVector(normal, new Quaternion.RotationAxis(tangents[index], Math.PI))); 

  let getAlignmentVectors = () => {
    switch (props.attitude.alignment.alignmentVector) {
      case SUN: {
        return sunVectors;
      }
      case VELOCITY_ECI: {
        return velocityEciVectors;
      }
      case NADIR: {
        return nadirVectors;
      }
      case ZENITH: {
        return zenithVectors;
      }
      case ORBIT_NORMAL: {
        return orbitNormalVectors;
      }
      case ORBIT_RADIAL: {
        return orbitRadials;
      }
      default: {
        return tangents
      }
    }
  }

  let getConstraintVectors = () => {
    switch (props.attitude.constraint.constraintVector) {
      case SUN: {
        return sunVectors;
      }
      case VELOCITY_ECI: {
        return velocityEciVectors;
      }
      case NADIR: {
        return nadirVectors;
      }
      case ZENITH: {
        return zenithVectors;
      }
      case ORBIT_NORMAL: {
        return orbitNormalVectors;
      }
      case ORBIT_RADIAL: {
        return orbitRadials;
      }
      default: {
        return zenithVectors
      }
    }
  }

  axis1 = getAlignmentVectors();
  let constraintVectors = getConstraintVectors();
  axis3 = axis1.map((axis_vector, index) => Vector3.Cross(axis_vector, constraintVectors[index]));
  axis2 = axis1.map((axis_vector, index) => Vector3.Cross(axis3[index], axis_vector));

  function rotateVector(vect, quat) {
    var matr = new Matrix();
    quat.toRotationMatrix(matr);
    var rotatedvect = Vector3.TransformCoordinates(vect, matr);
    return rotatedvect;
  }

  let getRoations = () => {
    switch (props.attitude.alignment.satVector + props.attitude.constraint.satVector) {
      case (BODY_X + BODY_Y): {
        return axis1.map((axis, index) => new Vector3.RotationFromAxis(axis, axis2[index], axis3[index]));
      }
      case (BODY_X + BODY_Z): {
        return axis1.map((axis, index) => {
          axis3[index] = rotateVector(axis3[index], new Quaternion.RotationAxis(axis, Math.PI));
          return new Vector3.RotationFromAxis(axis, axis3[index], axis2[index])});
      }
      case (BODY_Y + BODY_X): {
        return axis1.map((axis, index) => {
          axis3[index] = rotateVector(axis3[index], new Quaternion.RotationAxis(axis, Math.PI));
          return new Vector3.RotationFromAxis(axis2[index], axis, axis3[index])});
      }
      case (BODY_Y + BODY_Z): {
        return axis1.map((axis, index) => new Vector3.RotationFromAxis(axis3[index], axis, axis2[index]));
      }
      case (BODY_Z + BODY_X): {
        return axis1.map((axis, index) => new Vector3.RotationFromAxis(axis2[index], axis3[index], axis));
      }
      case (BODY_Z + BODY_Y): {
        return axis1.map((axis, index) => {
          axis3[index] = rotateVector(axis3[index], new Quaternion.RotationAxis(axis, Math.PI))
          return new Vector3.RotationFromAxis(axis3[index], axis2[index], axis)});
      }
      default: {
        return axis1.map((axis, index) => new Vector3.RotationFromAxis(axis, axis2[index], axis3[index]));
      }
    }
  }
  rotation = getRoations();

// =======================================================================
// =======================================================================

  // ISS BOX
  iss = MeshBuilder.CreateSphere("iss", {diameter: .025}, scene);
  iss.position = orbitPointsToShow[0];
  let issMaterial = new StandardMaterial("boxMaterial", scene);
  issMaterial.diffuseColor = new Color3(1.0, 1.0, 1.0);
  issMaterial.emissiveColor = new Color3(1.0, 1.0, 1.0);
  iss.material = issMaterial;
  // gl.addIncludedOnlyMesh(iss)
  var localOrigin = bu.localAxes(.1);
  localOrigin.parent = iss;

  // scene.debugLayer.show();
}

/**
 * Will run on every frame render.  We are spinning the iss on y-axis.
 */
const onRender = scene => {
  if (sun !== undefined) {
    sun.position = new Vector3(sunPosition[issPosIndex][0]*200, sunPosition[issPosIndex][1]*200, sunPosition[issPosIndex][2]*200);
  }
  if (iss !== undefined) {
    iss.rotation = rotation[issPosIndex];
    iss.position = orbitPointsToShow[issPosIndex] ? orbitPointsToShow[issPosIndex] : [0, 0, 0];

    lineOptions.points = orbitPointsToShow; 
    lineOptions.instance = lines;
    lines = MeshBuilder.CreateLines("lines", lineOptions);
    
    grid.gridRatio = Math.ceil(camera.radius / 10);
  }
  if (earth !== undefined && timeStamp !== undefined) {
    earth.rotation.z = rotAngle[issPosIndex][1];
  }
}

function ScenarioRender(props) {
  issPosIndex = props.satposindex;
  showposrange = props.showposrange;
  timeStamp = props.timestamps ? props.timestamps[issPosIndex] : undefined;
  
  //Array of points to construct a spiral with lines
  const r = props.satpositions;
  const orbitPoints = r.map(position => new Vector3(
    position[0] / earthRadius, 
    position[1] / earthRadius, 
    position[2] / earthRadius
  ));
  orbitPointsToShow = orbitPoints.slice(0);
  orbitPointsToShow.fill(orbitPoints[showposrange[0]],0,showposrange[0]);
  orbitPointsToShow.fill(orbitPoints[showposrange[1]],showposrange[1]);
  // satOrientation = props.satorientation;

  rotAngle = props.earthOrientation;
  sunPosition = props.sunPositionsEci;
  
  return (
    <div>
        <SceneComponent 
          antialias 
          onSceneReady={scene => onSceneReady(scene, props)} 
          onRender={onRender} 
          id='my-canvas' 
        />
    </div>
  )
}

const mapStateToProps = state => {
  return { 
    timestamps: state.scenarios[state.currentScenarioKey].analytics.predictions.satPos.timeStemps, 
    satpositions: state.scenarios[state.currentScenarioKey].analytics.predictions.satPos.positionsEci, 
    satorientation: state.scenarios[state.currentScenarioKey].analytics.predictions.satPos.orientationEci,
    earthOrientation: state.scenarios[state.currentScenarioKey].analytics.predictions.solarSystem.earth.orientation,
    sunPositionsEci: state.scenarios[state.currentScenarioKey].analytics.predictions.solarSystem.sun.positionEci,
    attitude: state.scenarios[state.currentScenarioKey].definitions.attitude, 
    satposindex: state.satellite.satPos.index, 
    showposrange: state.satellite.satPos.showTiemrange,
  };
};

export default connect(mapStateToProps)(ScenarioRender);
