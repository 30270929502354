import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ScenarioMenuButton from "./ScenarioMenuButton";
import { ReactComponent as EphLogo } from '../../eph_logo2.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../eph_logo.svg';


export default function HomeMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    location.pathname.includes('/scenario', 0) ?
    <Stack 
        spacing={2} 
        direction="row" 
        sx={{ position: 'absolute', top: 16, left: 16, zIndex: 10 }}
    >   
        <Button 
            variant="text" 
            size="large" 
            startIcon={<SvgIcon component={EphLogo} inheritViewBox color="primery" />}
            onClick={() => navigate('/')}
        >
            Home
        </Button>
        <ScenarioMenuButton />
    </Stack>
    :
    <Box sx={{ position: 'absolute', top: 16, left: 16, width: 200, cursor: 'pointer' }}>
        <img
            src={logo} 
            alt="logo"
            onClick={() => location.pathname === '/new' || navigate('/')}
        />
    </Box>
  );
}