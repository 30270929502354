import React from "react";
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import { addNewScenario, } from "../redux/actions";
import InputAdornment from '@mui/material/InputAdornment';
import { errorMsg, validateFields } from "./utils/formUtils";
import Box from '@mui/material/Box';


function ScenarioForm(props) {
    const initFields = {
        startTime: '2020-12-24T10:30',
        endTime: '2020-12-25T10:30',
        timeResolution: 60,
        invalidFields: {
          startTime: false,
          endTime: false,
          timeResolution: false,
        },
    };
    const [fields, setFields] = React.useState(initFields);

    const handleChange = (fieldname) => (event) => {
        let newFields = {...fields, [fieldname]: event.target.value};
        setFields(validateFields(newFields));
        let formVal = event.target.value;
        if (fieldname === 'timeResolution'){
            formVal = parseInt(formVal)
        }
        if ('name' === fieldname && validName(formVal)){
            props.addNewScenario(props.scenarioKey, {
                ...props.scenarios[props.scenarioKey],
                scenarioKey: props.scenarioKey, 
                [fieldname]: formVal});
        } else {
            props.addNewScenario(props.scenarioKey, { 
                ...props.scenarios[props.scenarioKey],
                scenarioKey: props.scenarioKey, 
                [fieldname]: formVal});
        }
    };

    const validName = (name) => {
        const maxNrOfChar = 20
        if (name.length > maxNrOfChar) {
            alert('Name length sholud be less than ' + maxNrOfChar.toString() + ' character long.')
            return false;
        }
        return true;
    };

    return (
        <div className="ScenarioForm">
            <TextField
                id="scenarioname"
                label="Set Scenario Name"
                value={props.scenarios[props.scenarioKey] ? props.scenarios[props.scenarioKey].name : ''}
                variant="outlined"
                onChange={handleChange('name')}
            />
            <br></br>
            <TextField
                id="description"
                label="Description"
                value={
                    props.scenarios[props.scenarioKey] 
                    ? props.scenarios[props.scenarioKey].description 
                    : ''
                }
                variant="outlined"
                multiline
                minRows={3}
                onChange={handleChange('description')}
                sx={{margin: 3, width: '25rem'}}
            />
            <Box
                component="form"
                sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    error={fields.invalidFields.startTime}
                    id="startTime"
                    label="Start time"
                    helperText={fields.invalidFields.startTime ? errorMsg.startTime : ' '}
                    type="datetime-local"
                    variant="outlined"
                    value={props.scenarios[props.scenarioKey].startTime.slice(0,16)}
                    onChange={handleChange('startTime')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    size="small"
                />

                <TextField
                    error={fields.invalidFields.endTime}
                    id="endTime"
                    label="End time"
                    helperText={fields.invalidFields.endTime ? errorMsg.endTime : ' '}
                    type="datetime-local"
                    variant="outlined"
                    value={props.scenarios[props.scenarioKey].endTime.slice(0,16)}
                    onChange={handleChange('endTime')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    size="small"
                />

                <TextField
                    error={fields.invalidFields.timeResolution}
                    id="timeResolution"
                    label="Time resolution"
                    helperText={fields.invalidFields.timeResolution ? errorMsg.timeResolution : ' '}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">sec</InputAdornment>,
                    }}
                    type="number"
                    variant="outlined"
                    value={props.scenarios[props.scenarioKey].timeResolution}
                    onChange={handleChange('timeResolution')}
                    size="small"
                />
            </Box>
        
        </div>
    );
}

const mapStateToProps = state => {
    return { scenarios: state.scenarios, scenarioKey: state.currentScenarioKey };
};

export default connect(mapStateToProps, { addNewScenario, })(ScenarioForm);