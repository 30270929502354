// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { DemoAttitude, DemoOrbit, DemoSatellite, DemoScenario, Attitude, Orbit, Satellite, Scenario, User, Organization } = initSchema(schema);

export {
  DemoAttitude,
  DemoOrbit,
  DemoSatellite,
  DemoScenario,
  Attitude,
  Orbit,
  Satellite,
  Scenario,
  User,
  Organization
};