import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import SurroundSoundIcon from '@mui/icons-material/SurroundSound';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';


function AnalyticsMenu(props) {
  const navigate = useNavigate(); 
  return (
    <React.Fragment>
        <MenuItem
            disabled={true}
        >
            <Typography>Analytics</Typography>
        </MenuItem>
        <MenuItem
            disabled={!props.done.satOrbit}
            onClick={() => navigate('/scenario/orbit')}
        >
          <ListItemIcon>
            <MultilineChartIcon fontSize="small" />
          </ListItemIcon>
          Orbit
        </MenuItem>
        <MenuItem
            disabled={!props.done.radiation}
            onClick={() => navigate('/scenario/radiation')}
        >
          <ListItemIcon>
            <SurroundSoundIcon fontSize="small" />
          </ListItemIcon>
          Radiation
        </MenuItem>
        <MenuItem
            disabled={!props.done.lifetime}
            onClick={() => navigate('/scenario/lifetime')}
        >
          <ListItemIcon>
            <AvTimerIcon fontSize="small" />
          </ListItemIcon>
          Lifetime
        </MenuItem>
        <MenuItem
            disabled={!props.done.incomingPower}
            onClick={() => navigate('/scenario/power')}
        >
          <ListItemIcon>
            <SolarPowerIcon fontSize="small" />
          </ListItemIcon>
          Power
        </MenuItem>
        <MenuItem
            disabled={!props.done.rf}
            onClick={() => navigate('/scenario/rfdata')}
        >
          <ListItemIcon>
            <CellWifiIcon fontSize="small" />
          </ListItemIcon>
          RF / Data
        </MenuItem>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return { done: state.doneAnalytics[state.currentScenarioKey] };
}

export default connect( mapStateToProps )(AnalyticsMenu);