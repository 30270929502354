import {
  ADD_NEWSCENARIO, 
  INIT_NEWSCENARIO,
  SET_SATCONFIG,
  SET_KEPLER, 
  SET_TLE, 
  SET_TLE_TIMES, 
  SET_SATTRACK,
  SET_SATATTITUDE,
  SET_EARTH, 
  SET_SUN, 
  SET_MOON,
  SET_ORBITTYPE,
  SET_INCOMINPOWER,
} from "../actionTypes";
import {
  BODY_Y,
  BODY_Z,
  SUN,
  NADIR,
} from '../../containers/utils/satAttitudes';


const initialState = {
      "235175ee-8518-4464-9018-73b259b0e7e5": {
        scenarioKey: '235175ee-8518-4464-9018-73b259b0e7e5',
        name: 'Demo Scenario',
        description: 'This is a Demo scenario. It demonstrates the capabilities of the software tools.',
        startTime: new Date('Sun Sep 20 2008 14:25:40 GMT+0200'),
        endTime: new Date('Sun Sep 21 2008 14:25:40 GMT+0200'),
        timeResolution: 60,
        definitions: {
          orbit: {
            type: "KEPLER",
            tle: {
                line1: '1 25544U 98067A   08264.51782528 -.00002182  00000-0 -11606-4 0  2927',
                line2: '2 25544  51.6416 247.4627 0006703 130.5360 325.0288 15.72125391563537',
            },
            kepler: {
                semiMajorAxis: 7500,
                inclination: 80,
                raan: 90,
                eccentricity: 0.003,
                argOfPeriapsis: 90,
                trueAnomaly: 0,
            },
          },
          attitude: {
            isRotating: false,
            rotation: {
              bodyX: 1,
              bodyY: 0,
              bodyZ: 0,
              rotationSpeed: 0.2,
            },
            alignment: {
                satVector: BODY_Z,
                alignmentVector: NADIR,
            },
            constraint: {
                satVector: BODY_Y,
                constraintVector: SUN,
            },
          },
          satellite: {
              satKey: "u3batman",
              type: "u3batman",
              name: "3U CubeSat",
              size: "3U",
              wings: "Batman",
              solarArea: 0,
              areaFile: "https://ephemersys-mvp-media-dev.s3-eu-west-1.amazonaws.com/sats/area_u3batman.json",
              solarCellNr: 0,
              mediaUrl: "https://ephemersys-mvp-media-dev.s3-eu-west-1.amazonaws.com/sats/03U_6_6_6_6_0_0_batman_6_0_thumbnail.mp4",
              mediaType: "video"
          },
        },
        analytics: {
          predictions: {
            satPos: {
              timeStemps: [],
              positionsEci: [],

            },
            solarSystem: {
              sun: {positionEci: [],},
            },
            radiation: {},
            power: [],
          },
        },
      },
      "new-scenario": {
        name: '',
        description: '',
        startTime: new Date().toISOString(),
        endTime: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString(),
        timeResolution: 60,
        definitions: {
          orbit: {
            type: "KEPLER",
            tle: {
                line1: '1 25544U 98067A   08264.51782528 -.00002182  00000-0 -11606-4 0  2927',
                line2: '2 25544  51.6416 247.4627 0006703 130.5360 325.0288 15.72125391563537',
            },
            kepler: {
                semiMajorAxis: 7500,
                inclination: 80,
                raan: 90,
                eccentricity: 0.003,
                argOfPeriapsis: 90,
                trueAnomaly: 0,
            },
          },
          attitude: {
            isRotating: true,
            rotation: {
              bodyX: 1,
              bodyY: 0,
              bodyZ: 0,
              rotationSpeed: 0.2,
            },
            alignment: {
                satVector: BODY_Z,
                alignmentVector: NADIR,
            },
            constraint: {
                satVector: BODY_Y,
                constraintVector: SUN,
            },
          },
          satellite: {
              satKey: "u3batman",
              type: "u3batman",
              name: "3U CubeSat",
              size: "3U",
              wings: "Batman",
              solarArea: 0,
              areaFile: "https://ephemersys-mvp-media-dev.s3-eu-west-1.amazonaws.com/sats/area_u3batman.json",
              solarCellNr: 0,
              mediaUrl: "https://ephemersys-mvp-media-dev.s3-eu-west-1.amazonaws.com/sats/03U_6_6_6_6_0_0_batman_6_0_thumbnail.mp4",
              mediaType: "video"
          },
        },
      }
}

export default function scenariosAction(state = initialState, action) {
  let scenarioKey = ''
  let tmpState = {}
  if (action.payload) {
    scenarioKey = action.payload.scenarioKey;
    tmpState = {...(state[scenarioKey] || {
      analytics: {
        predictions: {
          satPos: {timeStemps: [], positionsEci: [],},
          solarSystem: {sun: {positionEci: [],},},
          radiation: {},
          power: [],
        },
      },
      definitions: {attitude: {
        isRotating: true,
        rotation: {
          bodyX: 1,
          bodyY: 0,
          bodyZ: 0,
          rotationSpeed: 0.2,
        },
        alignment: {
            satVector: BODY_Z,
            alignmentVector: NADIR,
        },
        constraint: {
            satVector: BODY_Y,
            constraintVector: SUN,
        },
      },}
    })
    }
  }
  switch (action.type) {
    case ADD_NEWSCENARIO: {
        return {
            ...state,
            [scenarioKey]: {
                scenarioKey: scenarioKey,
                name: action.payload.name,
                description: action.payload.description,
                definitions: initialState['new-scenario'].definitions,
                ...action.payload,
            },
        };
    }
    case INIT_NEWSCENARIO: {
      return {
          ...state,
          'new-scenario': {
              scenarioKey: scenarioKey,
              ...initialState['new-scenario'],
              // definitions: {...initialState['new-scenario'].definitions},
          },
      };
    }
    case SET_ORBITTYPE: {
      return {
          ...state,
          [scenarioKey]: {
              ...state[scenarioKey],
              definitions: {
                ...state[scenarioKey].definitions,
                orbit: {
                  ...state[scenarioKey].definitions.orbit,
                  type: action.payload.type,
                },
              },
          },
      };
    }
    case SET_KEPLER: {
      return {
          ...state,
          [scenarioKey]: {
              ...state[scenarioKey],
              definitions: {
                ...state[scenarioKey].definitions,
                orbit: {
                  ...state[scenarioKey].definitions.orbit,
                  kepler: {
                    ...action.payload.kepler,
                  },
                },
              },
          },
      };
    }
    case SET_SATCONFIG: {
      return {
          ...state,
          [scenarioKey]: {
              ...state[scenarioKey],
              definitions: {
                ...state[scenarioKey].definitions,
                satellite: {
                  ...state[scenarioKey].definitions.satellite,
                  ...action.payload.satellite,

                },
              },
          },
      };
    }

    case SET_TLE: {
        const { line1, line2 } = action.payload.lines;
        return {
            ...state,
            [scenarioKey]: {
              ...state[scenarioKey],
              definitions: {
                ...state[scenarioKey].definitions,
                orbit: {
                  ...state[scenarioKey].definitions.orbit,
                  tle: {
                      ...state[scenarioKey].definitions.orbit.tle,
                      line1: line1,
                      line2: line2,
                  }
                },
              },
            },
        };
    }
    case SET_TLE_TIMES: {
        const { startTime, endTime, timeResolution } = action.payload.tleTimes;
        return {
          ...state,
          [scenarioKey]: {
            ...state[scenarioKey],
            definitions: {
              ...state[scenarioKey].definitions,
              orbit: {
                ...state[scenarioKey].definitions.orbit,
                tle: {
                    ...state[scenarioKey].definitions.orbit.tle,
                    startTime: startTime,
                    endTime: endTime,
                    timeResolution: timeResolution,
                },
              },
            },
          },
        };
    }
    case SET_SATTRACK: {
      return {
        ...state,
        [scenarioKey]: {
          ...tmpState,
          analytics: {
            ...tmpState.analytics,
            predictions: {
              ...tmpState.analytics.predictions,
              satPos:{
                ...tmpState.analytics.predictions.satPos,
                ...action.payload.track,
              }
            },  
          },
        },
      };
    }
    case SET_INCOMINPOWER: {
      return {
        ...state,
        [scenarioKey]: {
          ...tmpState,
          analytics: {
            ...tmpState.analytics,
            predictions: {
              ...tmpState.analytics.predictions,
              power: action.payload.powerTs,
            },  
          },
        },
      };
    }
    case SET_EARTH: {
      return {
        ...state,
        [scenarioKey]: {
          ...tmpState,
          analytics: {
            ...tmpState.analytics,
            predictions: {
              ...tmpState.analytics.predictions,
              solarSystem:{
                ...tmpState.analytics.predictions.solarSystem,
                earth: {
                  ...tmpState.analytics.predictions.solarSystem.earth,
                  ...action.payload.earth,
                },
              },
            },
          },
        },
      };
    }
    case SET_SUN: {
      return {
        ...state,
        [scenarioKey]: {
          ...tmpState,
          analytics: {
            ...tmpState.analytics,
            predictions: {
              ...tmpState.analytics.predictions,
              solarSystem:{
                ...tmpState.analytics.predictions.solarSystem,
                sun: {
                  ...tmpState.analytics.predictions.solarSystem.sun,
                  ...action.payload.sun,
                },
              },
            },
          },
        },
      };
    }
    case SET_MOON: {
      return {
        ...state,
        [scenarioKey]: {
          ...state[scenarioKey],
          analytics: {
            ...state[scenarioKey].analytics,
            predictions: {
              ...state[scenarioKey].analytics.predictions,
              solarSystem:{
                ...state[scenarioKey].analytics.predictions.solarSystem,
                moon: {
                  ...state[scenarioKey].analytics.predictions.solarSystem.moon,
                  ...action.payload.moon,
                },},},},},
      };
    }
    case SET_SATATTITUDE: {
      return {
        ...state,
        [scenarioKey]: {
          ...tmpState,
          definitions: {
            ...tmpState.definitions,
            attitude: {
              ...tmpState.definitions.attitude,
              ...action.payload.satattitude,
            },},}
      }
    }
    default:
      return state;
  }
}
