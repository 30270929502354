import { 
    propagateTleP, 
    propageteKeplerP,
    calcEarthOrientationsP, 
    calcSunPositionsP, 
} from '../../lib/orbitalMechanics';
import { calculateIncomingPowerTs, calculateSunVector, sunStatusTS } from '../../lib/satellitePower';



export function runPropagator(props) {
    console.log(props);
    let satTrack;
    if (props.orbit.type === 'KEPLER') {
      satTrack = propageteKeplerP({...props.orbit.kepler, ...props.times});
    } else if (props.orbit.type === 'TLE') {
      satTrack = propagateTleP({...props.orbit.tle, ...props.times});
    }
    satTrack.then(
      res => {
        props.setGroundTrack(props.scenarioKey, res);
        console.log({error: false, msg: 'Done!'});

        calcSunPositionsP(res.timeStemps)
        .then(
          res1 => {
            props.setSun(props.scenarioKey, res1);
            console.log({error: false, msg: 'Done!'});
          })
        .catch(
          err1 => {
            console.log({error: true, msg: err1});
          }
        )

        calcEarthOrientationsP(res.timeStemps)
        .then(
          res2 => {
            props.setEarth(props.scenarioKey, res2);
            console.log({error: false, msg: 'Done!'});
          })
        .catch(
          err2 => {
            console.log({error: true, msg: err2});
          }
        )
        props.setDoneAnalytics(props.scenarioKey, {satOrbit: true})
      }
    ).catch(
      err => {
        console.log({error: true, msg: err});
      }
    )
}

export function runRadiation(params) {
    params.setDoneAnalytics(params.scenarioKey, {radiation: true})
    console.log('Implement this!')
}

export function runLifetime(params) {
    params.setDoneAnalytics(params.scenarioKey, {lifetime: true})
    console.log('Implement this!')
}

export async function runPower(params) {
  console.log(params);
    const sunStatusTs = await sunStatusTS(params.satEciTs, params.sunEciTs)
    // .then(sunStatus => {
    //   console.log(sunStatus);
    //   // params.setSunStatus(sunStatus)
    // })
    // .catch(error => {
    //   console.log(error);
    // })
    const satAlphaBetaTs = await calculateSunVector(params.satEciTs, params.sunEciTs, params.attitude)
    // .then(res => {
    //   console.log(res);
    // })
    // .catch(error => {
    //   console.log(error);
    // })

    calculateIncomingPowerTs(satAlphaBetaTs, params.satPowerMap, sunStatusTs)
    .then(res => {
      console.log(res);
      params.setIncomingPower(params.scenarioKey, res);
    })
    .catch(error => {
      console.log(error);
    })
    .finally(() => {params.setDoneAnalytics(params.scenarioKey, {incomingPower: true})})
}

export function runRfData(params) {
    params.setDoneAnalytics(params.scenarioKey, {rf: true})
    console.log('Implement this!')
}

