export const BODY_X = "BODY_X";
export const BODY_Y = "BODY_Y";
export const BODY_Z = "BODY_Z";
export const SUN = "SUN";
export const VELOCITY_ECI = "VELOCITY_ECI";
export const NADIR = "NADIR";
export const ZENITH = "ZENITH";
export const ORBIT_NORMAL = "ORBIT_NORMAL";
export const ORBIT_RADIAL = "ORBIT_RADIAL";
export const VELOCITY_ECEF = "VELOCITY_ECEF";
