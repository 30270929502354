import React, { useEffect, useState } from "react";
import { Container } from "@mui/system";
import { connect } from "react-redux";
import { PowerChart3D, TsLineChart } from "./PowerChart3D";
import { getSatelliteByScenarioId } from "./utils/scenarioQery";
import { Paper, TextField, Box } from "@mui/material";


function PowerAnalysis(props) {
    const [isLoading, setLoading] = useState(true);
    const [jsonFiles, setJsonFiles] = useState({});
    const [solarRadiation, setSolarRadiation] = useState(1362);
    // const [satellite, setSatellite] = useState();
    // const [isDemo, setDemo] = useState(false);

    useEffect(() => {
        getSatelliteByScenarioId(props.scenarioKey)
        .then(({demo, satellite}) => {
            // setDemo(demo)
            // setSatellite(satellite)
            // console.log(satellite);

            fetch(satellite.areaFile)
            .then((response) => response.json())
            .then((json) => {setJsonFiles(json)})
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
        })
    },[props.scenarioKey])

    return(
        <Container maxWidth="xl" sx={{paddingTop: 10, paddingBottom: 10}}>
            <Paper elevation={0}>
                <h2>Incoming power calculation</h2>
                <p>Here you can see the incoming power of the satellite. The power is calculated based on the solar radiation and the area of the solar panels. By default the Solar radiation is 1362 W/m2 for Low Earth Orbits</p>

                <Box
                    component="img"
                    sx={{
                        width: 300,
                        height: 300,
                        backgroundColor: 'primary.dark',
                        '&:hover': {
                        backgroundColor: 'primary.main',
                        opacity: [0.9, 0.8, 0.7],
                        },
                    }}
                    src="/coordinates.png"
                />

                {/* <img style={{with: 30}} src="/coordinates.png" alt="Coordinare system" /> */}
                <p>You can set the solar radiation. Default is 1362 W/m2 for LEO.</p>
                <p>In the chart you can see the illumination against the Sun vector in the body fixed coordinate system of the satellite. The phi mesured from the x axes on the x-y plane and the theta measured from the x-y plane. This represents the maximum incoming power on the satellite for given directions.</p>
                <TextField
                    id="outlined-number"
                    label="Solar radiation [W/m2]"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={solarRadiation}
                    onChange={(e) => setSolarRadiation(e.target.value)}
                />
            </Paper>
            {!isLoading && <PowerChart3D data={Object.values(jsonFiles)[0]} solarRadiation={solarRadiation} />}
            
            <p>Here you can see the incoming power of the satellite over time. The power is calculated based on the solar radiation and the orientation of the satellite on the orbit. This represents the theoretical maximum of the incoming power for every moment.</p>
            {!isLoading && <TsLineChart ts={props.predictions.satPos.timeStemps} data={props.predictions.power} solarRadiation={solarRadiation} />}
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        predictions: state.scenarios[state.currentScenarioKey].analytics.predictions,
        scenarioKey: state.currentScenarioKey
    }
}

export default connect(mapStateToProps,)(PowerAnalysis)