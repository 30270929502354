import React, { useEffect, useState } from "react";
import NewScenarioCard from "./cards/NewScenarioCard";
import ScenarioCard from "./cards/ScenarioCard";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { listAllDemoScenario, listAllScenario } from "./utils/scenarioQery";


export default function Home() {
  const [isLoading, setLoading] = useState(true);
  const [isDemoLoading, setDemoLoading] = useState(true);
  const [demoScenarios, setDemoScenarios] = useState([]);
  const [scenarios, setScenarios] = useState([]);


  const getScenarios = () => {
    listAllScenario()
    .then(scenarios => {
      setScenarios(scenarios);
      setLoading(false);
    })
    console.log('called');
  }

  useEffect(() => {
    listAllDemoScenario()
    .then(scenarios => {
      setDemoScenarios(scenarios);
      setDemoLoading(false)
    })
    listAllScenario()
    .then(scenarios => {
      setScenarios(scenarios);
      setLoading(false);
    })
  }, [])
  
  const getDemoScenarioList = () => {
    return( demoScenarios.map((val, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <ScenarioCard 
          scenario={val}
          disableDelete={true}
        />
      </Grid>
    )));
  }

  const getScenarioList = () => {
    if (scenarios.length > 0){
      return( scenarios.map((val, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <ScenarioCard 
            scenario={val}
            getScenarios={getScenarios}
          />
        </Grid>
      )));
    } else {
      return(
        <Grid item xs={12} sm={6} md={4}>
          "No Saved Scenario"
        </Grid>
      );
    }
  }

  return (
    <div className="Home">
      <Container maxWidth='xl' sx={{paddingBottom: 5}}>
        { isDemoLoading ? <CircularProgress /> : (<Grid 
          container 
          spacing={2}
          sx={{paddingTop: 10, paddingBottom: 10}}
          >
            {getDemoScenarioList()}
          </Grid>)
        }
        { isLoading ? <CircularProgress /> : (<Grid 
          container 
          spacing={2}
          sx={{paddingTop: 10, paddingBottom: 10}}
          >
            {getScenarioList()}
          </Grid>)
        }
        <NewScenarioCard />
      </Container>
    </div>
  );
}
