import { 
  SET_TLE, 
  SET_SATTRACK, 
  SET_SATPOSITION, 
  SET_TLE_TIMES, 
  SET_SHOWTIMERANGE, 
  SET_KEPLER, 
  SET_EARTH, 
  SET_SUN, 
  SET_MOON,
  SET_SATATTITUDE,
  ADD_NEWSCENARIO,
  INIT_NEWSCENARIO,
  SET_CURRENTSCENARIOKEY,
  SET_SATCONFIG,
  SET_ANALYTICSDONE,
  SET_ORBITTYPE,
  IFINIT_ANALYTICSSTATUS,
  SET_INCOMINPOWER,
} from "./actionTypes";

export const setIncomingPower = (scenarioKey, powerTs) => ({
  type: SET_INCOMINPOWER,
  payload: {
    scenarioKey,
    powerTs,
  }
})

export const resetDoneAnalytics = scenarioKey => ({
  type: IFINIT_ANALYTICSSTATUS,
  payload: {
    scenarioKey,
  }
})

export const setDoneAnalytics = (scenarioKey, doneAnalytics) => ({
  type: SET_ANALYTICSDONE,
  payload: {
    scenarioKey,
    doneAnalytics,
  },
})

export const setCurrentScenarioKey = scenarioKey => ({
  type: SET_CURRENTSCENARIOKEY,
  payload: {
    scenarioKey,
  },
});

export const setTLE = (scenarioKey, lines) => ({
  type: SET_TLE,
  payload: {
    scenarioKey,
    lines,
  },
});

export const setTLETimes = (scenarioKey, tleTimes) => ({
  type: SET_TLE_TIMES,
  payload: {
    scenarioKey,
    tleTimes,
  },
});

export const setKepler = (scenarioKey, kepler) => ({
  type: SET_KEPLER,
  payload: {
    scenarioKey,
    kepler,
  },
});

export const setOrbitType = (scenarioKey, type) => ({
  type: SET_ORBITTYPE,
  payload: {
    scenarioKey,
    type,
  },
});

export const setSateConfig = (scenarioKey, satellite) => ({
  type: SET_SATCONFIG,
  payload: {
    scenarioKey,
    satellite,
  },
});

export const setGroundTrack = (scenarioKey, track) => ({
  type: SET_SATTRACK,
  payload: {
    scenarioKey,
    track,
  },
});

export const setEarth = (scenarioKey, earth) => ({
  type: SET_EARTH,
  payload: {
    scenarioKey,
    earth,
  },
});

export const setSun = (scenarioKey, sun) => ({
  type: SET_SUN,
  payload: {
    scenarioKey,
    sun,
  },
});

export const setMoon = (scenarioKey, moon) => ({
  type: SET_MOON,
  payload: {
    scenarioKey,
    moon,
  },
});

export const addNewScenario = (scenarioKey, data) => ({
  type: ADD_NEWSCENARIO,
  payload: {
    scenarioKey: scenarioKey,
    ...data,
  },
});

export const initNewScenario = (scenarioKey) => ({
  type: INIT_NEWSCENARIO,
  payload: {
    scenarioKey: scenarioKey,
  },
});

export const setShowTimerange = (showtimerange) => ({
  type: SET_SHOWTIMERANGE,
  payload: {
    showtimerange,
  },
});

export const setSatPosition = (satpos) => ({
  type: SET_SATPOSITION,
  payload: {
    satpos,
  },
});

export const setSatAttitude = (scenarioKey, satattitude) => ({
  type: SET_SATATTITUDE,
  payload: {
    scenarioKey,
    satattitude,
  },
});
