import React from "react";
import { Button, CircularProgress, TextField, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { connect } from "react-redux";
import { setTLE, setTLETimes } from "../redux/actions";
import { tleParser } from "./utils/tleParser";
import TleTable from "./ui-components/TleTable";
import { updateOrbitOfSat } from "./utils/scenarioQery";
import { useEffect } from "react";


const tleTheme = createTheme({
  typography: {
    fontFamily: ["Roboto Mono", "monospace"],
    fontWeightRegular: 500,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        fontSize: "16px",
      },
      input: {
        padding: "16px 14px",
        height: 42,
        width: '75ch',
        boxSizing: "border-box",
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: "16px",
      },
      outlined: {
        transform: "translate(12px, 15px) scale(1)",
      }
    },
  },
});

function TleForm(props) {
  const [tle, setTLElocal] = React.useState(
    ['1 25544U 98067A   08264.51782528 -.00002182  00000-0 -11606-4 0  2927',
     '2 25544  51.6416 247.4627 0006703 130.5360 325.0288 15.72125391563537'].join('\n')
  );
  const [isSaving, setSaving] = React.useState(false);

  useEffect(() => {
    if (props.orbit) {
      setTLElocal([props.orbit.tleLine1, props.orbit.tleLine2].join('\n'))
    }
    console.log('tikk');
  }, [props.orbit])

  const isValidTLE = ({line1, line2}) => {
    if ((line1.length === 69) && (line2.length === 69)) {
      return(true);      
    }
    return(false);
  }

  const handleTLEChange = (event) => {
    let lines = event.target.value.split('\n');
    let line1 = lines[0] ? lines[0] : '';
    line1 = line1.length >= 69 ? line1.slice(0, 69) : line1;
    let line2 = lines[1] ? lines[1] : '';
    line2 = line2.length >= 69 ? line2.slice(0, 69) : line2;

    setTLElocal([line1, line2].join('\n'));

    if (isValidTLE({line1, line2})) {
      if (props.scenarioKey === 'new-scenario') {
        props.setTLE(props.scenarioKey, {line1, line2});
      }
    }
  };

  const handleSave = () => {
    setSaving(true)
    console.log(tle);
    const lines = tle.split('\n');
    updateOrbitOfSat(props.scenarioKey, {
      tleLine1: lines[0],
      tleLine2: lines[1],
      type: 'TLE',
    })
    .then(setSaving(false))
  }

  const tleTables = tleParser(tle);

  return (
    <Container maxWidth={false}>
      <ThemeProvider theme={tleTheme}>
        <form 
          noValidate 
          autoComplete="off"
        >
          <ThemeProvider theme={tleTheme}>
            <TextField
              fullWidth
              id="tle-field"
              label="Two Line Element"
              helperText="Some important text"
              multiline
              minRows={2}
              value={tle}
              onChange={handleTLEChange}
              variant="outlined"
              size="small"
              disabled={props.isDemo}
            />
          </ThemeProvider>
        </form>
      </ThemeProvider>
      {(props.scenarioKey === 'new-scenario') ||
        <Button 
          variant="contained"
          onClick={handleSave}
          disabled={isSaving || props.isDemo}
        >
          {isSaving ? <CircularProgress /> : 'Save'}
        </Button>
      }
      <br></br>
      <TleTable tleTables={tleTables} />
    </Container>
  );
}

const mapStateToProps = state => {
  return { scenarioKey: state.currentScenarioKey, };
};

export default connect(
  mapStateToProps,
  { setTLE, setTLETimes }
)(TleForm);
