import React from 'react';
import { connect } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SatDesign from './SatDesign';
import ScenarioForm from './ScenarioForm';
import MissionDesign from './MissionDesign';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import AttitudeProfile from './AttitudeProfile';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Container } from '@mui/material';
import { initNewScenario } from "../redux/actions";
import { saveNewScenario } from './utils/scenarioQery';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function getSteps() {
  return ['New Scenario', 'Select Satellite Design', 'Set the Mission Profile', 'Set the Nominal Attitude'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <ScenarioForm />;
    case 1:
      return <SatDesign newScenario={true} />;
    case 2:
      return <MissionDesign />;
    case 3:
      return <AttitudeProfile />;
    default:
      return 'Unknown step';
  }
}

function NewScenario(props) {
  const navigate = useNavigate();
  const [openErr, setOpenErr] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = getSteps();

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErr(false);
  };

  const handleNext = () => {
    if (activeStep === 0) {
        if ([undefined, ''].includes(props.scenario.name)) {
          setOpenErr(true);
          return;
        }
    }
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    if (completed[0] ^ (activeStep === steps.length) ) {
      setActiveStep(step);
    }
  };

  const handleComplete = () => {
    const scenarioParams = {
      "name": props.scenario.name,
      "description": props.scenario.description,
      "startTime": props.scenario.startTime,
      "endTime": props.scenario.endTime,
      "timeResolution": props.scenario.timeResolution
    }
    const satelliteParams = {
      "satKey": props.scenario.definitions.satellite.satKey,
      "name": props.scenario.definitions.satellite.name,
      "size": props.scenario.definitions.satellite.size,
      "wings": props.scenario.definitions.satellite.wings,
      "solarArea": props.scenario.definitions.satellite.solarArea,
      "solarCellNr": props.scenario.definitions.satellite.solarCellNr,
      "mediaUrl":  props.scenario.definitions.satellite.mediaUrl,
      "mediaType": props.scenario.definitions.satellite.mediaType,
      "areaFile":  props.scenario.definitions.satellite.areaFile
    }
    const orbitParams = {
      "tleLine1": props.scenario.definitions.orbit.tle.line1,
      "tleLine2": props.scenario.definitions.orbit.tle.line2,
      "inclination": props.scenario.definitions.orbit.kepler.inclination,
      "raan": props.scenario.definitions.orbit.kepler.raan,
      "eccentricity": props.scenario.definitions.orbit.kepler.eccentricity,
      "argOfPeriapsis": props.scenario.definitions.orbit.kepler.argOfPeriapsis,
      "trueAnomaly": props.scenario.definitions.orbit.kepler.trueAnomaly,
      "semiMajorAxis": props.scenario.definitions.orbit.kepler.semiMajorAxis,
      "type": props.scenario.definitions.orbit.type
    }
    const attitudeParams = {
      "alignmentSatVector": props.scenario.definitions.attitude.alignment.satVector,
      "alignmentVector": props.scenario.definitions.attitude.alignment.alignmentVector,
      "constraintSatVector": props.scenario.definitions.attitude.constraint.satVector,
      "constraintVector": props.scenario.definitions.attitude.constraint.constraintVector,
      "rotation": props.scenario.definitions.attitude.isRotating,
      "rotationAxisX": props.scenario.definitions.attitude.rotation.bodyX,
      "rotationAxisY": props.scenario.definitions.attitude.rotation.bodyY,
      "rotationAxisZ": props.scenario.definitions.attitude.rotation.bodyZ,
      "rotationSpeed": props.scenario.definitions.attitude.rotation.rotationSpeed
    }
    saveNewScenario(scenarioParams, satelliteParams, orbitParams, attitudeParams)
    handleNext();
    props.initNewScenario('new-scenario');
    navigate('/');
  };

  const handleCancel = () => {
    props.initNewScenario('new-scenario');
    navigate('/');
  }

  return (
    <Container maxWidth='xl' sx={{paddingTop: 10, paddingBottom: 10}}>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton 
                onClick={handleStep(index)} 
                completed={completed[index] ? "true" : "false"}
            >
                <StepLabel>
                    {label}
                </StepLabel>
            </StepButton>
            <StepContent>
              {getStepContent(index)}
              <Box sx={{ p: 1, m: 1, }}>
                  <Button
                    disabled={false}
                    onClick={handleCancel}
                    variant="outlined"
                    sx={{ p: 1, m: 1, }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="outlined"
                    sx={{ p: 1, m: 1, }}
                  >
                    Back
                  </Button>
                  {activeStep !== steps.length &&
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={
                          activeStep === steps.length - 1 
                          ? handleComplete 
                          : handleNext
                        }
                        sx={{ p: 1, m: 1, }}
                    >
                        {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                    </Button>
                  }
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0}>
          <Typography>All steps completed - you&apos;re finished</Typography>
        </Paper>
      )}
      <Snackbar open={openErr} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity={"error"} sx={{ width: '100%' }}>
          Name error: Field cannot be empty!
        </Alert>
      </Snackbar>
    </Container>
  );
}

const mapStateToProps = state => {
    return { scenario: state.scenarios[state.currentScenarioKey] };
};

export default connect(mapStateToProps, { initNewScenario })(NewScenario);