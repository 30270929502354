import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';


export default function TleTable(props) {
    return(
    <Grid container spacing={3}>
        <Grid item md={12} lg={6}>
          <TableContainer component={Paper}>
            <Table 
              size="small" 
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Field Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tleTables.rows1.map((row) => (
                  <TableRow key={row.index}>
                    <TableCell scope="row">
                      {row.index}
                    </TableCell>
                    <TableCell>{row.field}</TableCell>
                    <TableCell>{row.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item md={12} lg={6}>
          <TableContainer component={Paper}>
            <Table 
              size="small" 
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Field Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tleTables.rows2.map((row) => (
                  <TableRow key={row.index}>
                    <TableCell scope="row">
                      {row.index}
                    </TableCell>
                    <TableCell>{row.field}</TableCell>
                    <TableCell>{row.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
    </Grid>
    )
}