
 const validationCriteria = {
    startTime: { min: 1, max: 2},
    endTime: { min: 1, max: 2},
    timeResolution: { min: 1, max: 3600},
    semiMajorAxis: { min: 5000, max: 60000},
    eccentricity: { min: 0, max: 1},
    inclination: { min: 0, max: 180},
    longitudeAscNode: { min: 0, max: 360},
    argOfPeriapsis: { min: 0, max: 360},
    trueAnomaly: { min: 0, max: 360},
  }

  export const errorMsg = {
    startTime: 'Wrong Date Time Format!',
    endTime: 'Wrong Date Time Format!',
    timeResolution: ['Time resolution must be between', 
                      validationCriteria.timeResolution.min, 
                      'and', 
                      validationCriteria.timeResolution.max,].join(' '),
    semiMajorAxis: ['Semi-Major Axis must be between', 
                      validationCriteria.semiMajorAxis.min, 
                      'and', 
                      validationCriteria.semiMajorAxis.max,].join(' '),
    eccentricity: ['Eccentricity must be between', 
                    validationCriteria.eccentricity.min, 
                    'and', 
                    validationCriteria.eccentricity.max,].join(' '),
    inclination: ['Inclination must be between', 
                    validationCriteria.inclination.min, 
                    'and', 
                    validationCriteria.inclination.max,].join(' '),
    longitudeAscNode: ['Longitude of the ascending node must be between', 
                        validationCriteria.longitudeAscNode.min, 
                        'and', 
                        validationCriteria.longitudeAscNode.max,].join(' '),
    argOfPeriapsis: ['Argument of periapsis must be between', 
                      validationCriteria.argOfPeriapsis.min, 
                      'and', 
                      validationCriteria.argOfPeriapsis.max,].join(' '),
    trueAnomaly: ['True Anomaly must be between', 
                  validationCriteria.trueAnomaly.min, 
                  'and', 
                  validationCriteria.trueAnomaly.max,].join(' '),
  }

  const validTimeResolution = (fields) => {
    if ((fields.timeResolution < validationCriteria.timeResolution.min) || 
        (fields.timeResolution > validationCriteria.timeResolution.max)){
      return({...fields, invalidFields: {...fields.invalidFields, timeResolution: true,}});
    } else {
      return({...fields, invalidFields: {...fields.invalidFields, timeResolution: false,}});
    }
  };
  const validSemiMajorAxis = (fields) => {
    if ((fields.semiMajorAxis < validationCriteria.semiMajorAxis.min) || 
        (fields.semiMajorAxis > validationCriteria.semiMajorAxis.max)){
      return({...fields, invalidFields: {...fields.invalidFields, semiMajorAxis: true,}});
    } else {
      return({...fields, invalidFields: {...fields.invalidFields, semiMajorAxis: false,}});
    }
  };
  const validEccentricity = (fields) => {
    if ((fields.eccentricity < validationCriteria.eccentricity.min) || 
        (fields.eccentricity > validationCriteria.eccentricity.max)){
      return({...fields, invalidFields: {...fields.invalidFields, eccentricity: true,}});
    } else {
      return({...fields, invalidFields: {...fields.invalidFields, eccentricity: false,}});
    }
  };
  const validInclination = (fields) => {
    if ((fields.inclination < validationCriteria.inclination.min) || 
        (fields.inclination > validationCriteria.inclination.max)){
      return({...fields, invalidFields: {...fields.invalidFields, inclination: true,}});
    } else {
      return({...fields, invalidFields: {...fields.invalidFields, inclination: false,}});
    }
  };
  const validLongitudeAscNode = (fields) => {
    if ((fields.longitudeAscNode < validationCriteria.longitudeAscNode.min) || 
        (fields.longitudeAscNode > validationCriteria.longitudeAscNode.max)){
      return({...fields, invalidFields: {...fields.invalidFields, longitudeAscNode: true,}});
    } else {
      return({...fields, invalidFields: {...fields.invalidFields, longitudeAscNode: false,}});
    }
  };
  const validArgOfPreiapsis = (fields) => {
    if ((fields.argOfPeriapsis < validationCriteria.argOfPeriapsis.min) || 
        (fields.argOfPeriapsis > validationCriteria.argOfPeriapsis.max)){
      return({...fields, invalidFields: {...fields.invalidFields, argOfPeriapsis: true,}});
    } else {
      return({...fields, invalidFields: {...fields.invalidFields, argOfPeriapsis: false,}});
    }
  };
  const validTrueAnomaly = (fields) => {
    if ((fields.trueAnomaly < validationCriteria.trueAnomaly.min) || 
        (fields.trueAnomaly > validationCriteria.trueAnomaly.max)){
      return({...fields, invalidFields: {...fields.invalidFields, trueAnomaly: true,}});
    } else {
      return({...fields, invalidFields: {...fields.invalidFields, trueAnomaly: false,}});
    }
  };

  export function validateFields(fields) {
    fields = validTimeResolution(fields);
    fields = validSemiMajorAxis(fields);
    fields = validEccentricity(fields);
    fields = validInclination(fields);
    fields = validLongitudeAscNode(fields);
    fields = validArgOfPreiapsis(fields);
    fields = validTrueAnomaly(fields);
    return(fields);
  }