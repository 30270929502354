import { SET_SATPOSITION, SET_SHOWTIMERANGE } from "../actionTypes";

const initialState = {
    type: '',
    satPos: {
        index: 0,
        timeStemp: '',
        groundPosition: [],
        positionEci: [],
        showTiemrange: [0, 1],
    },
};

export default function satPositionAction(state = initialState, action) {
  switch (action.type) {
    case SET_SATPOSITION: {
      return {
        ...state,
        satPos: {
            ...state.satPos,
            ...action.payload.satpos,
        },
      };
    }
    case SET_SHOWTIMERANGE: {
      return {
        ...state,
        satPos: {
            ...state.satPos,
            ...action.payload.showtimerange,
        },
      }
    }
    default:
      return state;
  }
}
