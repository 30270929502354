import { combineReducers } from "redux";
import satellite from "./satellite";
import scenarios from "./scenarios";
import currentScenarioKey from "./currentScenarioKey";
import doneAnalytics from "./doneAnalytics";

export default combineReducers({ 
    satellite, 
    scenarios, 
    currentScenarioKey,
    doneAnalytics,
});
