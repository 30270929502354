import React from "react";
import { MapContainer, 
         TileLayer, 
         Marker, 
         Popup, 
         Polyline, 
         Polygon, 
         ZoomControl,
         LayersControl,
         ScaleControl  } from 'react-leaflet';
import terminator from '@joergdietrich/leaflet.terminator';
import { connect } from "react-redux";
import './GroundTrack.css';
import L from 'leaflet';

var satIcon = L.icon({
  iconUrl: '../sat_icon.png',
  iconSize: [38, 38],
  iconAnchor: [22, 30],
  popupAnchor: [0, -38],
  shadowUrl: '../sat_icon_shadow.png',
  shadowSize: [0, 0],
  shadowAnchor: [0, 0]
});

function deepSlice(array, start, end) {

  let lengs = array.map( (item) => {
    return(item.length);
  });

  let chomped = array.filter((item, index) => {
    let initTot = lengs.slice(0, index+1).reduce( (total, item) => (total + item) );
    return(!((start+1 > initTot) || (end+item.length+1 <= initTot)));
  });

  return(chomped);
}

function GroundTrack(props) {
  const center = [0, 0];
  const bounds = [
    [-90, 180],
    [90, -180],
  ]

  let terminatorPol = terminator();
  let dtime = props.timestemps.length > 0 ? new Date(props.timestemps[props.groundposindex]) : new Date();
  terminatorPol.setTime(dtime);

  let satGroundTrack = deepSlice(props.groundtrack, props.groundposrange[0], props.groundposrange[1]);

  const limeOptions = { color: 'blue', weight: '1' };
    return (
        <div id="map-wrapper">
          <MapContainer 
            center={center} 
            zoom={3} 
            minZoom={1.75} 
            maxZoom={10} 
            scrollWheelZoom={true} 
            bounds={bounds} 
            maxBoundsViscosity={1.0} 
            zoomSnap={0.1}
            zoomControl={false}
          >
            <LayersControl position="topright">
              <LayersControl.BaseLayer checked name="Map: OpenStreetMap">
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Topographic: OpenTopoMap">
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellite: NASA">
                <TileLayer
                  attribution='&copy; <a href="https://www.earthdata.nasa.gov/engage/open-data-services-and-software">NASA Blue Marble, image service by OpenGeo</a>'
                  url="https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/BlueMarble_ShadedRelief_Bathymetry/default//EPSG3857_500m/{z}/{y}/{x}.jpeg"
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellite: ERIS">
                <TileLayer
                  attribution='&copy; <a href="http://www.esri.com/">Esri</a>'
                  url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />
              </LayersControl.BaseLayer>
            </LayersControl>

            <Marker 
              position={(props.groundtrack[0].length > 0) ? props.groundtrack.flat(1)[props.groundposindex] : [0.0, 0.0]} 
              icon={satIcon}
            >
              <Popup>
                Satellite position
              </Popup>
            </Marker>
            <Polyline pathOptions={limeOptions} positions={satGroundTrack}>
              <Popup>
                Ground Track
              </Popup>
            </Polyline>
            <Polygon 
              positions={terminatorPol._latlngs} 
              pathOptions={{ 
                color: 'black', 
                fillColor: 'black', 
                fillOpacity: 0.3, 
                stroke: false 
              }} 
            />
            <ZoomControl position="topright" />
            <ScaleControl position="bottomright" />
            {/* <SetBoundsRectangles /> */}
          </MapContainer>
        </div>
    );
}

const mapStateToProps = state => {
  return { 
    timestemps: state.scenarios[state.currentScenarioKey].analytics.predictions.satPos.timeStemps, 
    groundtrack: state.scenarios[state.currentScenarioKey].analytics.predictions.satPos.groundTrack, 
    groundposindex: state.satellite.satPos.index, 
    groundposrange: state.satellite.satPos.showTiemrange,
   };
};

export default connect(mapStateToProps)(GroundTrack)